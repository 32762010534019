/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-form"]';

/**
 * Friendation component
 */
export const basisForm = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((component) => {
    const handleForm = () => {
      const form = component.querySelector('form');
      if (!form) return;

      const onSubmit = (e: SubmitEvent) => {
        e.preventDefault();

        form.setAttribute('aria-busy', 'true');

        const submitButtons: (HTMLButtonElement | HTMLInputElement)[] =
          Array.from(
            form.querySelectorAll(
              '[type="submit"], button:not([type="button"])'
            )
          );

        /**
         * Disable submit button to prevent multiple submissions
         * @see {@link https://app.asana.com/0/1203369933318419/1205497928943168/f}
         */
        submitButtons.forEach((submitButton) => {
          submitButton.disabled = true;
        });

        form.submit();
      };

      form.addEventListener('submit', onSubmit);
    };

    handleForm();
  });
};
