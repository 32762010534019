interface FreeFormReadyEvent extends SubmitEvent {
  freeform: any;
  form: HTMLFormElement;
}

export const basisFreeform = () => {
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll('[is="basis-freeform"]')
  );

  components.forEach((component) => {
    /**
     * Depending on what happens with the form, its state may change. For example a form could fail to submit or a submission could be successfull. We can react to the state changes.
     */
    const handleStateChanges = () => {
      const form = component.querySelector('form');

      const callback = (mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            /**
             * Do stuff when the form is submitted successfully
             */
            const handleSuccessMessage = () => {
              /** There should be a message element to tell the user that something went wrong or right, after the {@link form} was submitted. */
              const successNotification: HTMLElement | null =
                form.querySelector('.ff-form-success');

              /** Set keyboard focus to the {@link notification} */
              if (successNotification) {
                successNotification.tabIndex = -1;

                setTimeout(() => {
                  successNotification.focus();
                  setTimeout(() => {
                    successNotification.scrollIntoView({
                      block: 'center'
                    });
                  }, 1000);
                }, 100); // Chrome timeout
              }
            };

            /**
             * Do stuff when the form is not submitted due to errors
             */
            const handleErrorMessages = () => {
              // Stop the default field error rendering
              const elementsWithErrors: (
                | HTMLInputElement
                | HTMLSelectElement
              )[] = Array.from(form.querySelectorAll('.ff-has-errors'));

              // At this point we could to fancy stuff with aria but the could also keep it simple...

              /** Focus on the first element with an error. This is the default behaviour. The plugin Freeform breaks the default behaviour, we have to restore it. */
              elementsWithErrors[0]?.focus();
            };

            handleSuccessMessage();
            handleErrorMessages();
          }
        }
      };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      observer.observe(form, {
        childList: true,
        attributes: false,
        subtree: false
      });
    };

    handleStateChanges();
  });

  /**
   * Freeform Javascript Plugin
   * @see {@link https://docs.solspace.com/craft/freeform/v4/developer/js-plugin/}
   */
  const handleFreeformPlugin = () => {
    /** Listen for ready */
    document.addEventListener(
      'freeform-ready',
      function (event: FreeFormReadyEvent) {
        const handleRequiredFields = () => {
          const requiredFields: (HTMLInputElement | HTMLSelectElement)[] =
            Array.from(event.form.querySelectorAll('[data-required]'));

          requiredFields.forEach((field) => {
            /** The plugin Freeform uses custom error messages, therefore the `required` attribute is not present. We can add the `aria-required` to support screen reader users. */
            field.setAttribute('aria-required', 'true');
          });
        };

        /**
         * Improve the accessibility of the drag-and-drop file upload field
         */
        const handleFileInputFields = () => {
          const fields: HTMLElement[] = Array.from(
            event.form.querySelectorAll(
              '[data-freeform-file-upload="fileDragAndDrop"]'
            )
          );

          fields.forEach((field) => {
            /** Add element to the tabindex */
            field.tabIndex = 0;

            field.addEventListener('keydown', (e: KeyboardEvent) => {
              /** The drag and drop file input field does not support Enter or Space key as it should. It does support click – so we fire click on Space or Enter keydown. */
              if (['Space', 'Enter'].includes(e.code)) {
                e.preventDefault();
                field.click();
              }
            });
          });
        };

        handleRequiredFields();
        handleFileInputFields();
      }
    );
  };

  handleFreeformPlugin();
};
