interface aButtonOptions {
  buttonArrowSelector?: string;
}

export const aButton = (options?: aButtonOptions) => {
  /**
   * When a paragraph only contains buttons, we need to apply styles to that paragraph
   */
  const handleParagraphs = () => {
    const paragraphs: HTMLParagraphElement[] = Array.from(
      document.querySelectorAll('p')
    );
    paragraphs.forEach((paragraph) => {
      const children = Array.from(paragraph.children);

      if (children.length > 1) {
        if (children.find((x) => x.classList.contains('button'))) {
          // Remove unnessessary non breaking spaces that Craft creates when the user hits the spacebar too often
          paragraph.innerHTML = paragraph.innerHTML.replace(/&nbsp;/g, '');

          /** Paragraph contains only .button elements */
          paragraph.classList.add('buttonWrapper');
        }
      }
    });
  };

  const handleButtonArrow = () => {
    const arrowIcon = `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 24 24" viewBox="0 0 24 24"><path d="M17.7 11.6 12 5.9c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l7 7c.3.3.3.8 0 1.1l-7 7c-.3.3-.8.3-1.1 0s-.3-.8 0-1.1l5.7-5.7H3.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h13.9z" style="fill-rule:evenodd;clip-rule:evenodd;fill:currentColor"/></svg>`;

    const buttons: (HTMLButtonElement | HTMLAnchorElement)[] = Array.from(
      document.querySelectorAll(
        options && options.buttonArrowSelector
          ? options.buttonArrowSelector
          : '.button--arrow'
      )
    );

    buttons.forEach((button) => {
      const iconWrapper = document.createElement('span');
      iconWrapper.classList.add('iconWrapper');
      iconWrapper.innerHTML = arrowIcon;
      iconWrapper.setAttribute('aria-hidden', 'true');
      button.append(iconWrapper);
    });
  };

  const handleIconOnlyButtons = () => {
    const buttons: (HTMLButtonElement | HTMLAnchorElement)[] = Array.from(
      document.querySelectorAll('.button')
    );

    buttons.forEach((button) => {
      if (
        button.innerText.length === 0 &&
        button.childElementCount === 1 &&
        !button.classList.contains('button--arrow') &&
        !button.classList.contains('button--icon')
      ) {
        button.classList.add('button--iconOnly');
      }
    });
  };

  handleParagraphs();
  handleButtonArrow();
  handleIconOnlyButtons();
};
