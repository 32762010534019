import Rellax, { RellaxInstance, RellaxOptions } from 'rellax';
import { throttle } from 'throttle-debounce';
import { getCssPropertyValue } from '../../ts/helper';

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="hero-bild-text"]';

/**
 * Friendation component
 */
export const heroBildText = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((component) => {
    const handleParallax = () => {
      const itemA: HTMLElement = component.querySelector(
        '.images-item:nth-child(1)'
      );
      const itemB: HTMLElement = component.querySelector(
        '.images-item:nth-child(2)'
      );
      const itemC: HTMLElement = component.querySelector(
        '.images-item:nth-child(3)'
      );
      const itemD: HTMLElement = component.querySelector(
        '.images-item:nth-child(4)'
      );

      if (!itemA || !itemB || !itemC || !itemD) return;

      let rellaxA: undefined | RellaxInstance;
      let rellaxB: undefined | RellaxInstance;
      let rellaxC: undefined | RellaxInstance;
      let rellaxD: undefined | RellaxInstance;

      const breakpoint = parseInt(
        getCssPropertyValue('--breakpoint-tablet'),
        10
      );

      const onResize = () => {
        if (window.innerWidth >= breakpoint) {
          if (!rellaxA && !rellaxB && !rellaxC && !rellaxD) {
            let config: RellaxOptions = {
              speed: -1.2,
              center: true
            };

            rellaxA = new Rellax(itemA, config);

            config = {
              speed: 2,
              center: true
            };
            rellaxB = new Rellax(itemB, config);

            config = {
              speed: -2,
              center: true
            };
            rellaxC = new Rellax(itemC, config);

            config = {
              speed: 1,
              center: true
            };
            rellaxD = new Rellax(itemD, config);
          }
        } else {
          if (rellaxA && rellaxB && rellaxC && rellaxD) {
            rellaxA.destroy();
            rellaxB.destroy();
            rellaxC.destroy();
            rellaxD.destroy();
            rellaxA = undefined;
            rellaxB = undefined;
            rellaxC = undefined;
            rellaxD = undefined;
          }
        }
      };

      const tick = throttle(100, () => {
        onResize();
      });

      window.addEventListener('resize', tick);
      onResize();
    };

    handleParallax();
  });
};
