export const lazyLoading = () => {
  const images: HTMLImageElement[] = Array.from(
    document.querySelectorAll('img[loading="lazy"]')
  );
  if ('loading' in HTMLImageElement.prototype) {
    images.forEach((img) => {
      if (img.hasAttribute('data-src')) img.src = img.dataset.src;
      img.srcset = img.dataset.srcset;
    });
  } else {
    // Dynamically import the LazySizes library
    const script = document.createElement('script');
    images.forEach((img) => {
      img.classList.add('lazyload');
    });
    script.src = '/assets/scripts/lazysizes.min.js';
    document.body.appendChild(script);
  }
};
