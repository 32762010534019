/**
 * @typedef {Object} a11yAccordionOptions Accessible accordion (W3C) options
 * @property {Element} el Any element that is the accordion and contains {@link triggers}
 * @property {NodeListOf<HTMLButtonElement>} triggers Toggle elements for the accordion group (children of {@link el})
 * @property {() => a11yAccordionCallbackOptions=} onTrigger Callback function to call when one of {@link triggers} is triggered
 * @property {boolean=} multiple Allow for multiple accordion sections to be expanded at the same time if `true`
 * @property {boolean=} toggle Allow for each toggle to both open and close individually if `true`
 */

import { animations } from '../../../ts/animations';

/**
 * @typedef {Object} a11yAccordionCallbackOptions Accessible accordion (W3C) trigger callback
 * @property {Element} accordion The accordion element
 * @property {Element} trigger The trigger that was triggered in {@link accordion}
 * @property {Element} panel The panel associated with {@link trigger}
 */

/**
 * Accessible accordion (W3C)
 * @license https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
 * @param {a11yAccordionOptions} options Options
 * @returns {void}
 * @example
 *   <div class="accordion" data-allow-multiple data-allow-toggle>
 *     <div class="item">
 *       <button class="trigger" id="trigger-x" aria-controls="panel-x" aria-expanded="false"></button>
 *       <div class="panel" id="panel-x" aria-labelledby="trigger-x" role="region" inert></div>
 *     </div>
 *   </div>
 *
 *   <script>
 *     const onTrigger = (options) => { console.log(options) }
 *     const acc = document.querySelector('.accordion')
 *     const triggers = acc.querySelectorAll('.trigger')
 *     a11yAccordion({
 *       el: acc,
 *       triggers,
 *       onTrigger,
 *       multiple: true,
 *       toggle: true
 *     })
 *   </script>
 */
export const a11yAccordion = (options) => {
  if (!options.el) throw Error("Missing required argument 'options.acc'");
  if (!options.triggers)
    throw Error("Missing required argument 'options.triggerElements'");
  if (options.multiple && typeof options.multiple !== 'boolean')
    throw TypeError(
      `Wrong type given, expected argument 'options.multiple' to be of type 'boolean' but received '${typeof options.multiple}'`
    );
  if (options.toggle && typeof options.toggle !== 'boolean')
    throw TypeError(
      `Wrong type given, expected argument 'options.toggle' to be of type 'boolean' but received '${typeof options.toggle}'`
    );
  if (options.onTrigger && typeof options.onTrigger !== 'function')
    throw TypeError(
      `Wrong type given, expected argument 'options.callback' to be of type 'function' but received '${typeof options.onTrigger}'`
    );

  const triggerArray = Array.from(options.triggers);

  /** Allow for multiple accordion sections to be expanded at the same time */
  const allowMultiple =
    options.el.hasAttribute('data-allow-multiple') || options.multiple;

  /** Allow for each toggle to both open and close individually */
  const allowToggle =
    allowMultiple ||
    options.el.hasAttribute('data-allow-toggle') ||
    options.toggle;

  let inProgress = false;

  /**
   * click event callback for {@link triggerArray}
   * @param {MouseEvent} e Click event
   * @returns {void}
   */
  const onTriggerClick = (e) => {
    if (inProgress) return;
    /** Trigger for a accordion section */
    const targetTrigger = e.currentTarget;

    /** @type {string} `id` of the panel elemnt that the {@link targetTrigger} triggers */
    let panelId;

    /** @type {Element} Panel that is controlled by {@link targetTrigger} */
    let targetPanel;

    /** Check if the current toggle is expanded. */
    const isExpanded = targetTrigger.getAttribute('aria-expanded') === 'true';

    /** Check if the current toggle is expanded. */
    const active = options.el.querySelector('[aria-expanded="true"]');

    // without allowMultiple, close the open accordion
    if (!allowMultiple && active && active !== targetTrigger) {
      // Set the expanded state on the triggering element
      active.setAttribute('aria-expanded', 'false');

      panelId = active.getAttribute('aria-controls');

      if (!panelId) throw Error("Missing required 'panelId'");

      // Hide the accordion sections, using aria-controls to specify the desired section
      targetPanel = document.getElementById(panelId);

      if (!targetPanel) throw Error("Missing required 'targetPanel'");

      inProgress = true;
      animations.slideToggle(targetPanel, () => {
        inProgress = false;
      });

      // When toggling is not allowed, clean up disabled state
      if (!allowToggle) {
        active.removeAttribute('aria-disabled');
      }
    }

    if (!isExpanded) {
      // Set the expanded state on the triggering element
      targetTrigger.setAttribute('aria-expanded', 'true');

      panelId = targetTrigger.getAttribute('aria-controls');
      if (!panelId) throw Error("Missing required 'panelId'");

      // Hide the accordion sections, using aria-controls to specify the desired section
      targetPanel = document.getElementById(panelId);

      if (!targetPanel) throw Error("Missing required 'targetPanel'");

      inProgress = true;
      animations.slideToggle(targetPanel, () => {
        inProgress = false;
      });

      // If toggling is not allowed, set disabled state on trigger
      if (!allowToggle) {
        targetTrigger.setAttribute('aria-disabled', 'true');
      }
    } else if (allowToggle && isExpanded) {
      // Set the expanded state on the triggering element
      targetTrigger.setAttribute('aria-expanded', 'false');

      panelId = targetTrigger.getAttribute('aria-controls');
      if (!panelId) throw Error("Missing required 'panelId'");

      // Hide the accordion sections, using aria-controls to specify the desired section
      targetPanel = document.getElementById(panelId);
      if (!targetPanel) throw Error("Missing required 'targetPanel'");
      inProgress = true;
      animations.slideToggle(targetPanel, () => {
        inProgress = false;
      });
    }

    e.preventDefault();

    if (options.onTrigger) {
      options.onTrigger.call(this, {
        accordion: options.el,
        trigger: targetTrigger,
        panel: targetPanel
      });
    }
  };

  /**
   * On {@link el} keydown event callback
   * @param {KeyboardEvent} e Keyboard event
   * @returns {void}
   */
  const onAccordionKeydown = (e) => {
    const target = e.target;
    const key = e.which.toString();

    // 33 = Page Up, 34 = Page Down
    const ctrlModifier = e.ctrlKey && key.match(/33|34/);

    // Is this coming from an accordion header?
    if (triggerArray.includes(target)) {
      /*
       * Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
       * 38 = Up, 40 = Down
       */
      if (key.match(/38|40/) || ctrlModifier) {
        const index = triggerArray.indexOf(target);
        const direction = key.match(/34|40/) ? 1 : -1;
        const length = triggerArray.length;
        const newIndex = (index + length + direction) % length;

        triggerArray[newIndex].focus();

        e.preventDefault();
      } else if (key.match(/35|36/)) {
        // 35 = End, 36 = Home keyboard operations
        switch (key) {
          // Go to first accordion
          case '36':
            triggerArray[0].focus();
            break;
          // Go to last accordion
          case '35':
            triggerArray[triggerArray.length - 1].focus();
            break;
        }
        e.preventDefault();
      }
    }
  };

  // Bind mouse behaviors on the main accordion container

  if (triggerArray && triggerArray.length) {
    for (let i = 0, n = Array.from(triggerArray); i < n.length; i++) {
      const trigger = n[i];

      trigger.addEventListener('click', onTriggerClick);
    }
    // triggerArray.forEach((trigger) => {
    //   trigger.addEventListener("click", onTriggerClick);
    // });
  }

  // Bind keyboard behaviors on the main accordion container
  options.el.addEventListener('keydown', onAccordionKeydown);

  // These are used to style the accordion when one of the buttons has focus
  triggerArray.forEach((trigger) => {
    trigger.addEventListener('focus', () => {
      options.el.classList.add('focus');
    });

    trigger.addEventListener('blur', () => {
      options.el.classList.remove('focus');
    });
  });

  /*
   * Minor setup: will set disabled state, via aria-disabled, to an
   * expanded/ active accordion which is not allowed to be toggled close
   */
  if (!allowToggle) {
    /** The first expanded/ active accordion */
    const expanded = options.el.querySelector('[aria-expanded="true"]');

    // If an expanded/ active accordion is found, disable
    if (expanded) {
      expanded.setAttribute('aria-disabled', 'true');
    }
  }
};
