import { throttle } from 'throttle-debounce';
import { getBoundingClientRectWithoutTransform } from './helper';

/**
 * Handle viewport relative animations. Elements with the 'animation' class will start their animation, when the element is in viewport and passes a specified point of interest ("poi").
 * @param poi From `0.1` to `1` {@link thePoi}
 * @example
 *   <div class="animation animation--fadeIn"></div>
 */
export const initViewportRelatedAnimations = (poi?: number) => {
  /** Animation class name indicating that the „element has a viewport relative animation” */
  const cnAnimation = 'animation';

  /** Animation classname indicating that the „elements animation has started” */
  const cnAnimationStarted = 'animation--started';

  /**
   * If this variable has the minimum value of `0.1`, the animation will only start when the element has approached the upper 10% of the screen edge - which is very late.
   * If, on the other hand, this value is `0.5`, the animation already starts when the element has crossed the centre of the screen.
   * If this variable has the maximum value of `1`, the animation starts when the element crosses the bottom edge of the screen, i.e. as soon as it enters the viewport, which is usually too early.
   */
  const thePoi = poi || 0.8;

  /** All elements that will be animated when the element is in viewport */
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(
    `.${cnAnimation}`
  );

  const onScroll = () => {
    for (let i = 0, n = Array.from(elements); i < n.length; i++) {
      const item: HTMLElement = n[i];

      if (!item.classList.contains(cnAnimationStarted)) {
        const itemRect = getBoundingClientRectWithoutTransform(item);

        // we pass the point of interest
        if (itemRect.top <= window.innerHeight * thePoi) {
          // and trigger the animation
          item.classList.add(cnAnimationStarted);
        }
      }
    }
  };

  if (elements && elements.length) {
    const throttleTick = throttle(100, () => {
      onScroll();
    });

    window.addEventListener('scroll', throttleTick);

    setTimeout(() => {
      /** Trigger an additional time to make sure elements actually animate, in case you cannot scroll on the page. This should actually be handeled by the parameter `callOnLoad` in {@link throttleTick} but it sometimes is not enough. So this is not a „we must do this”, more like a „we should do this”. */
      onScroll();
    }, 300);
  }
};

/**
 * Handle animation delays should there be an exact number of items and animation-delay properties added to said items.
 * @example
 *   <div class="animation animation--fadeIn animation--delay"></div>
 */
export const initDelayedAnimations = () => {
  /** @type {String} Animation class name indicating that the „element has a viewport relative animation” */
  const cnAnimationDelay = 'animation--delay';

  /** @type {String} Animation classname indicating that the „elements animation has started” */
  const cnAnimationDelayStarted = 'animation--delay-started';

  /** @type {NodeListOf<HTMLElement>} All elements that will be animated when the element is in viewport */
  const elements = document.querySelectorAll(`.${cnAnimationDelay}`);

  const handleDelay = () => {
    elements.forEach((x) => x.classList.add(cnAnimationDelayStarted));
  };

  if (elements && elements.length) {
    handleDelay();
  }
};

/**
 * Animation properties for {@link animations}
 * @param el Any element
 * @example
 *   ```css
 *   .foo {
 *     transition: max-height 0.5s ease, opacity 0.2s linear;
 *   }
 *   ```
 *   ```js
 *   getAnimationProperties(document.querySelector('.foo')).transition // 'max-height 0.5s ease, opacity 0.2s linear'
 *   ```
 */
const getAnimationProperties = (el: HTMLElement) => {
  const cmpt = window.getComputedStyle(el);

  return {
    /**
     * The original transition value from CSS
     * @example
     *   'max-height 0.5s ease, opacity 0.2s linear'
     */
    transition: cmpt.transition
  };
};

/** Elements that are currently animating. This can be used to avoid triggering any animation twice. */
const currentlyAnimatedObjects: Element[] = [];

/**
 * Friendation Animations. These animations are based on CSS transitions.
 * @requires {@link getAnimationProperties}
 * @requires {@link currentlyAnimatedObjects}
 */
export const animations = {
  /**
   * Reveal a hidden element by fading it in. This is a CSS animation that uses `'opacity'`. You need to set some CSS properties: `'transition: opacity [duration] [timing-function]'`.
   * @param el Any element
   * @param onDone Callback function when the animation is over
   * @example
   *   ```css
   *   .foo {
   *     transition: opacity 1s linear;
   *     &:not([inert]) {
   *       opacity: 1;
   *     }
   *     &[inert] {
   *       opacity: 0;
   *     }
   *   }
   *   ```
   *   ```js
   *   fadeIn(document.querySelector('.foo'))
   *   ```
   */
  fadeIn: (el: HTMLElement, onDone: (el: HTMLElement) => void) => {
    if (currentlyAnimatedObjects.includes(el)) return;

    /** Add {@link el} to the {@link currentlyAnimatedObjects} to avoid multiple animations */
    currentlyAnimatedObjects.push(el);

    const props = getAnimationProperties(el);
    const from = 0;
    const to = 1;

    /** Do not animate if the element is already visible */
    if (window.getComputedStyle(el).opacity === to.toString()) return;

    /** Before animation starts */
    const before = () => {
      el.style.transition = 'unset';
      el.style.opacity = from.toFixed(2);
    };

    /** Start animation */
    const start = () => {
      el.style.transition = props.transition;
      el.style.opacity = to.toFixed(2);
    };

    /** After animation is completed */
    const done = () => {
      el.removeEventListener('transitionend', done);
      el.inert = false;
      el.style.removeProperty('opacity');
      el.style.removeProperty('transition');
      currentlyAnimatedObjects.splice(currentlyAnimatedObjects.indexOf(el), 1);
      if (onDone) onDone.call(this, el);
    };

    before();

    setTimeout(() => {
      start();
    }, 1);

    el.addEventListener('transitionend', done);
  },

  /**
   * Hide a visible element by fading it out. This is a CSS animation that uses `'opacity'`. You need to set some CSS properties: `'transition: opacity [duration] [timing-function]'`.
   * @param el Any element
   * @param onDone Callback function when the animation is over
   * @example
   *   ```css
   *   .foo {
   *     transition: opacity 1s linear;
   *     &:not([inert]) {
   *       opacity: 1;
   *     }
   *     &[inert] {
   *       opacity: 0;
   *     }
   *   }
   *   ```
   *   ```js
   *   fadeOut(document.querySelector('.foo'))
   *   ```
   */
  fadeOut: (el: HTMLElement, onDone: (el: HTMLElement) => void) => {
    if (currentlyAnimatedObjects.includes(el)) return;

    /** Add {@link el} to the {@link currentlyAnimatedObjects} to avoid multiple animations */
    currentlyAnimatedObjects.push(el);

    const props = getAnimationProperties(el);
    const from = 1;
    const to = 0;

    /** Do not animate if the element is already hidden */
    if (window.getComputedStyle(el).opacity === to.toString()) return;

    /** Before animation starts */
    const before = () => {
      el.style.transition = 'unset';
      el.style.opacity = from.toFixed(2);
    };

    /** Start animation */
    const start = () => {
      el.style.transition = props.transition;
      el.style.opacity = to.toFixed(2);
    };

    /** After animation is completed */
    const done = () => {
      el.removeEventListener('transitionend', done);
      el.inert = true;
      el.style.removeProperty('opacity');
      el.style.removeProperty('transition');
      currentlyAnimatedObjects.splice(currentlyAnimatedObjects.indexOf(el), 1);
      if (onDone) onDone.call(this, el);
    };

    before();

    setTimeout(() => {
      start();
    }, 1);

    el.addEventListener('transitionend', done);
  },

  /**
   * Reveal a hidden element by sliding it down. This is a CSS animation that uses `'max-height'`. You need to set some CSS properties: `'transition: max-height [duration] [timing-function]'`.
   * @param el Any element
   * @param onDone Callback function when the animation is over
   * @example
   *   ```css
   *   .foo {
   *     transition: max-height 1s linear;
   *     overflow: hidden;
   *
   *     &[inert] {
   *       max-height: 0; // initial state
   *     }
   *   }
   *   ```
   *   ```js
   *   slideDown(document.querySelector('.foo'))
   *   ```
   */
  slideDown: (el: HTMLElement, onDone: (el: HTMLElement) => void) => {
    if (currentlyAnimatedObjects.includes(el)) return;

    /** Add {@link el} to the {@link currentlyAnimatedObjects} to avoid multiple animations */
    currentlyAnimatedObjects.push(el);

    const props = getAnimationProperties(el);
    const from = '0px';
    const to = el.scrollHeight + 'px';

    /** Before animation starts */
    const before = () => {
      el.style.transition = 'unset';
      el.style.maxHeight = from;
      el.style.overflow = 'hidden';
    };

    /** Start animation */
    const start = () => {
      el.style.transition = props.transition;
      el.style.maxHeight = to;
    };

    /** After animation is completed */
    const done = () => {
      el.removeEventListener('transitionend', done);
      el.inert = false;
      el.style.removeProperty('overflow');
      el.style.removeProperty('max-height');
      el.style.removeProperty('transition');
      currentlyAnimatedObjects.splice(currentlyAnimatedObjects.indexOf(el), 1);
      if (onDone) onDone.call(this, el);
    };

    before();

    setTimeout(() => {
      start();
    }, 1);

    el.addEventListener('transitionend', done);
  },

  /**
   * Hide a visible element by sliding it up.  This is a CSS animation that uses `'max-height'`. You need to set some CSS properties: `'transition: max-height [duration] [timing-function]'`.
   * @param el Any element
   * @param  onDone Callback function when the animation is over
   * @example
   *   ```css
   *   .foo {
   *     transition: max-height 1s linear;
   *     overflow: hidden;
   *
   *     &[inert] {
   *       max-height: 0; // initial state
   *     }
   *   }
   *   ```
   *   ```js
   *   slideUp(document.querySelector('.foo'))
   *   ```
   */
  slideUp: (el: HTMLElement, onDone: (el: HTMLElement) => void) => {
    if (currentlyAnimatedObjects.includes(el)) return;

    /** Add {@link el} to the {@link currentlyAnimatedObjects} to avoid multiple animations */
    currentlyAnimatedObjects.push(el);

    const props = getAnimationProperties(el);
    const from = el.scrollHeight + 'px';
    const to = '0px';

    /** Before animation starts */
    const before = () => {
      el.style.transition = 'unset';
      el.style.maxHeight = from;
      el.style.overflow = 'hidden';
    };

    /** Start animation */
    const start = () => {
      el.style.transition = props.transition;
      el.style.maxHeight = to;
    };

    /** After animation is completed */
    const done = () => {
      el.removeEventListener('transitionend', done);
      el.inert = true;
      el.style.removeProperty('overflow');
      el.style.removeProperty('max-height');
      el.style.removeProperty('transition');
      currentlyAnimatedObjects.splice(currentlyAnimatedObjects.indexOf(el), 1);
      if (onDone) onDone.call(this, el);
    };

    el.addEventListener('transitionend', done);

    before();

    setTimeout(() => {
      start();
    }, 1);
  },

  /**
   * Show a visible element by sliding it down and fading it in.  This is a CSS animation that uses `'max-height'` and `'opacity'`. You need to set some CSS properties: `'transition: max-height [duration] [timing-function], opacity [duration] [timing-function]'`.
   * @param el Any element
   * @param onDone Callback function when the animation is over
   * @example
   *   ```css
   *   .foo {
   *     transition: max-height 1s linear, opacity 1s linear;
   *     overflow: hidden;
   *
   *     &[inert] {
   *       max-height: 0; // initial state
   *       opacity: 0; // initial state
   *     }
   *   }
   *   ```
   *   ```js
   *   slideDownFade(document.querySelector('.foo'))
   *   ```
   */
  slideDownFade: (el: HTMLElement, onDone: (el: HTMLElement) => void) => {
    if (currentlyAnimatedObjects.includes(el)) return;

    const props = getAnimationProperties(el);
    const maxHeightFrom = '0px';
    const maxHeightTo = el.scrollHeight + 'px';
    const opacityFrom = 0;
    const opacityTo = 1;
    const transformFrom = 'translateY(-10px)';
    const transformTo = 'translateY(0px)';

    /** Do not animate if the element is already visible */
    if (window.getComputedStyle(el).opacity === opacityTo.toString()) return;

    /** Add {@link el} to the {@link currentlyAnimatedObjects} to avoid multiple animations */
    currentlyAnimatedObjects.push(el);

    /** Before animation starts */
    const before = () => {
      el.style.transition = 'unset';
      el.style.maxHeight = maxHeightFrom;
      el.style.opacity = opacityFrom.toFixed(2);
      el.style.transform = transformFrom;
      el.style.overflow = 'hidden';
    };

    /** Start animation */
    const start = () => {
      el.style.transition = props.transition;
      el.style.maxHeight = maxHeightTo;
      el.style.opacity = opacityTo.toFixed(2);
      el.style.transform = transformTo;
    };

    /** After animation is completed */
    const done = () => {
      el.removeEventListener('transitionend', done);
      el.inert = false;
      el.style.removeProperty('overflow');
      el.style.removeProperty('max-height');
      el.style.removeProperty('opacity');
      el.style.removeProperty('transition');
      el.style.removeProperty('transform');
      currentlyAnimatedObjects.splice(currentlyAnimatedObjects.indexOf(el), 1);
      if (onDone) onDone.call(this, el);
    };

    el.addEventListener('transitionend', done);

    before();

    setTimeout(() => {
      start();
    }, 1);
  },

  /**
   * Hide a visible element by sliding it up and fading it out.  This is a CSS animation that uses `'max-height'` and `'opacity'`. You need to set some CSS properties: `'transition: max-height [duration] [timing-function], opacity [duration] [timing-function]'`.
   * @param el Any element
   * @param onDone Callback function when the animation is over
   * @example
   *   ```css
   *   .foo {
   *     transition: max-height 1s linear, opacity 1s linear;
   *     overflow: hidden;
   *
   *     &[inert] {
   *       max-height: 0; // initial state
   *       opacity: 0; // initial state
   *     }
   *   }
   *   ```
   *   ```js
   *   slideUpFade(document.querySelector('.foo'))
   *   ```
   */
  slideUpFade: (el: HTMLElement, onDone: (el: HTMLElement) => void) => {
    if (currentlyAnimatedObjects.includes(el)) return;

    const props = getAnimationProperties(el);
    const maxHeightFrom = el.scrollHeight + 'px';
    const maxHeightTo = '0px';
    const opacityFrom = 1;
    const opacityTo = 0;
    const transformFrom = 'translateY(0px)';
    const transformTo = 'translateY(-10px)';

    /** Do not animate if the element is already hidden */
    if (window.getComputedStyle(el).opacity === opacityTo.toString()) return;

    /** Add {@link el} to the {@link currentlyAnimatedObjects} to avoid multiple animations */
    currentlyAnimatedObjects.push(el);

    /** Before animation starts */
    const before = () => {
      el.style.transition = 'unset';
      el.style.maxHeight = maxHeightFrom;
      el.style.opacity = opacityFrom.toFixed(2);
      el.style.transform = transformFrom;
      el.style.overflow = 'hidden';
    };

    /** Start animation */
    const start = () => {
      el.style.transition = props.transition;
      el.style.maxHeight = maxHeightTo;
      el.style.opacity = opacityTo.toFixed(2);
      el.style.transform = transformTo;
    };

    /** After animation is completed */
    const done = () => {
      el.removeEventListener('transitionend', done);
      el.inert = true;
      el.style.removeProperty('overflow');
      el.style.removeProperty('max-height');
      el.style.removeProperty('opacity');
      el.style.removeProperty('transition');
      el.style.removeProperty('transform');
      currentlyAnimatedObjects.splice(currentlyAnimatedObjects.indexOf(el), 1);
      if (onDone) onDone.call(this, el);
    };

    el.addEventListener('transitionend', done);

    before();

    setTimeout(() => {
      start();
    }, 1);
  },

  /**
   * Hide or reveal an element by sliding it up or down and fading it in or out, depending on whether it is currently inert.  This is a CSS animation that uses `'max-height'` and `'opacity'`. You need to set some CSS properties: `'transition: max-height [duration] [timing-function], opacity [duration] [timing-function]'`, `'overflow: hidden'`.
   * @param el Any element
   * @param onDone Callback function when the animation is over
   * @example
   *   ```css
   *   .foo {
   *     transition: max-height 1s linear, opacity 1s linear;
   *     overflow: hidden;
   *
   *     &[inert] {
   *       max-height: 0; // initial state
   *       opacity: 0; // initial state
   *     }
   *   }
   *   ```
   *   ```js
   *   slideFadeToggle(document.querySelector('.foo'))
   *   ```
   */
  slideFadeToggle: (el: HTMLElement, onDone: (el: HTMLElement) => void) => {
    if (el.inert) {
      animations.slideDown(el, onDone);
    } else {
      animations.slideUp(el, onDone);
    }
  },

  /**
   * Hide or reveal an element by sliding it up or down, depending on whether it is currently inert.  This is a CSS animation that uses `'max-height'`. You need to set some CSS properties: `'transition: max-height [duration] [timing-function]'`, `'overflow: hidden'`.
   * @param el Any element
   * @param onDone Callback function when the animation is over
   * @example
   *   ```css
   *   .foo {
   *     transition: max-height 1s linear;
   *     overflow: hidden;
   *
   *     &[inert] {
   *       max-height: 0; // initial state
   *     }
   *   }
   *   ```
   *   ```js
   *   slideToggle(document.querySelector('.foo'))
   *   ```
   */
  slideToggle: (el: HTMLElement, onDone: (el: HTMLElement) => void) => {
    if (el.inert) {
      animations.slideDown(el, onDone);
    } else {
      animations.slideUp(el, onDone);
    }
  }
};
