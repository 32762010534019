/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-events-archiv"]';

/**
 * Friendation component
 */
export const basisEventsArchiv = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((component) => {
    /**
     * Load More behaviour
     */
    const handleLoadMore = () => {
      const posts = component.querySelectorAll('.post');
      const loadMoreBtn = component.querySelector('.posts-loadMore-button');
      const maxPosts = 9;
      let visiblePosts = 0;

      posts.forEach((post) => {
        if (!post.classList.contains('post-hide')) {
          visiblePosts++;
        }
      });

      if (visiblePosts > maxPosts) {
        for (let i = maxPosts; i < visiblePosts; i++) {
          posts[i].classList.add('post-hide');
        }

        loadMoreBtn?.setAttribute('data-hide', 'false');

        loadMoreBtn.addEventListener('click', () => {
          for (let i = maxPosts; i < visiblePosts; i++) {
            posts[i].classList.remove('post-hide');
          }
          loadMoreBtn?.setAttribute('data-hide', 'true');
        });
      } else {
        loadMoreBtn?.setAttribute('data-hide', 'true');
      }
    };

    /**
     * Filter behaviour
     */
    const handleFilter = () => {
      const filterBtns = component.querySelectorAll('.filter-item-link');

      filterBtns.forEach((btn) => {
        const onClick = (e) => {
          e.preventDefault();
          handleLoadMore();

          const selectedBtn = e.target;
          const selectedCategory = e.target.getAttribute('data-category');
          const selectedAllCategories =
            e.target.getAttribute('data-categories');

          const posts = component.querySelectorAll('.post');

          filterBtns.forEach((button) => {
            if (button === selectedBtn) {
              button.classList.remove('button--secondary');
              button.classList.add('button--primary');
            } else {
              button.classList.remove('button--primary');
              button.classList.add('button--secondary');
            }
          });

          posts.forEach((post) => {
            const postCategories = post.querySelectorAll('.category');
            let showPost = false;

            if (selectedAllCategories) {
              showPost = true;
            }

            postCategories.forEach((postCategory) => {
              if (postCategory.textContent.trim() === selectedCategory) {
                showPost = true;
              }
            });

            if (!showPost) {
              post.classList.add('post-hide');
            } else {
              post.classList.remove('post-hide');
            }
          });
        };

        btn.addEventListener('click', onClick);
      });
    };

    handleLoadMore();
    handleFilter();
  });
};
