import Rellax, { RellaxInstance } from 'rellax';
import { throttle } from 'throttle-debounce';
import { getCssPropertyValue } from '../../ts/helper';

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="block-call-to-action"]';

/**
 * Friendation component
 */
export const blockCallToAction = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((component) => {
    const handleParallax = () => {
      const media: HTMLElement | null = component.querySelector('.media');
      if (!media) return;

      let rellaxMedia: undefined | RellaxInstance;

      const breakpoint = parseInt(
        getCssPropertyValue('--breakpoint-tablet'),
        10
      );

      const destroy = () => {
        if (rellaxMedia) {
          rellaxMedia.destroy();
          rellaxMedia = undefined;
        }
      };

      const rebuild = () => {
        if (window.innerWidth >= breakpoint) {
          if (!rellaxMedia) {
            rellaxMedia = new Rellax(media, {
              speed: 0.5,
              center: true
            });
          }
        }
      };

      const onResize = () => {
        if (window.innerWidth >= breakpoint) {
          rebuild();
        } else {
          destroy();
        }
      };

      const tick = throttle(100, () => {
        onResize();
      });

      window.addEventListener('resize', tick);

      /**
       * Bugfix for {@link https://app.asana.com/0/1203221712639929/1204942092581535/f}. Clicking on an accordion item will increase the height of the document. This shifts the parallax effect in an unwanted position.
       * @param e Mouse event
       */
      const rellaxFixOnClick = (e: MouseEvent) => {
        if (!e || !e.target) return;
        const targetElement = <HTMLElement>e.target;
        if (
          targetElement &&
          targetElement.classList.contains('panel-trigger')
        ) {
          setTimeout(() => {
            if (rellaxMedia) {
              destroy();
            }

            rebuild();
          }, 500);
        }
      };

      document.body.addEventListener('click', rellaxFixOnClick);

      onResize();
    };

    handleParallax();
  });
};
