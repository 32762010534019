export const aBlockEmbedRaw = () => {
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll('[is="block-embed-raw"]')
  );

  components.forEach((component) => {
    const iframe: HTMLIFrameElement = component.querySelector('iframe');

    if (iframe) {
      /** Iframe inner height in pixel. Possibly throws an error when the client blocks this information. */
      let height: number;

      iframe.addEventListener('load', () => {
        try {
          /** This might be blocked by the client CORS policy */
          height =
            iframe.contentWindow &&
            iframe.contentWindow.document &&
            iframe.contentWindow.document.body &&
            iframe.contentWindow.document.body.scrollHeight
              ? iframe.contentWindow.document.body.scrollHeight
              : undefined;
        } catch (error) {
          height = parseInt(component.dataset.minHeight, 10);
          console.warn(`${error} Using minimumHeight of ${height}px instead.`);
        }

        if (typeof height === 'number' && height > 0)
          iframe.style.height = height + 'px';

        /** Fix iframes that come with `<iframe scrolling="no">` for no reason at all. */
        if (iframe.getAttribute('scrolling') === 'no')
          iframe.setAttribute('scrolling', 'yes');
      });
    }
  });
};
