export const aInput = () => {
  /**
   * Change the styling of form element depending on the state
   */
  const handleValidity = () => {
    const initClassName = 'initialized';

    const elements: HTMLElement[] = Array.from(
      document.querySelectorAll('[is="a-input"]')
    );

    /**
     * Check any form element for its validity and apply some classes so we can identify and modify the styles with CSS
     * @param element The [is="a-input"] element
     * @param input Any form element
     * @param onlyIfHasInitialValue Set to true when running checkElement before any changes were made to the {@link input}, for example on page load
     */
    const checkElement = (
      element: HTMLElement,
      input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
      onlyIfHasInitialValue?: boolean
    ) => {
      /** This element is valid on blur or not */
      const isValid =
        input.value &&
        !['on', 'off'].includes(input.value) &&
        input.checkValidity();
      const hasValue =
        input.value && !['on', 'off'].includes(input.value) ? true : false;
      const isInvalid =
        (input.value &&
          !['on', 'off'].includes(input.value) &&
          !input.checkValidity()) ||
        (input.required && !input.checkValidity());

      element.classList.toggle('hasValue', hasValue);

      if (onlyIfHasInitialValue !== true) {
        element.classList.toggle('isValid', isValid);
        element.classList.toggle('isInvalid', isInvalid);
      }
    };

    const init = () => {
      elements.forEach((element) => {
        const input:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement = Array.from(
          element.querySelectorAll('input, textarea, select')
        ).map((x) => {
          if (x.nodeName === 'SELECT') return <HTMLSelectElement>x;
          if (x.nodeName === 'TEXTAREA') return <HTMLTextAreaElement>x;
          return <HTMLInputElement>x;
        })[0];

        element.classList.add(initClassName);

        input.addEventListener('blur', () => {
          checkElement(element, input);
        });
        input.addEventListener('change', () => {
          checkElement(element, input);
        });
        input.addEventListener('focus', () => {
          checkElement(element, input);
        });
      });
    };

    const onKeydown = () => {
      init();
    };

    document.addEventListener('keydown', onKeydown);

    elements.forEach((element) => {
      const input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement =
        Array.from(element.querySelectorAll('input, textarea, select')).map(
          (x) => {
            if (x.nodeName === 'SELECT') return <HTMLSelectElement>x;
            if (x.nodeName === 'TEXTAREA') return <HTMLTextAreaElement>x;
            return <HTMLInputElement>x;
          }
        )[0];

      checkElement(element, input, true);
    });
  };

  /**
   * Add icons to <select> element
   */
  const handleSelect = () => {
    const elements: HTMLElement[] = Array.from(
      document.querySelectorAll('[is="a-input"][data-type="select"]')
    );

    const iconHtml = `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 11 5.9" viewBox="0 0 11 5.9"><path d="M5.5 4.8.9.1C.7 0 .3 0 .1.1 0 .3 0 .7.1.9l4.8 4.8c.3.3.9.3 1.2 0L10.9.9c.2-.2.2-.5 0-.7s-.5-.2-.7 0L5.5 4.8z" style="fill-rule:evenodd;clip-rule:evenodd;fill:currentColor"/></svg>`;

    elements.forEach((element) => {
      const inputWrapper: HTMLElement = element.querySelector('.inputWrapper');
      if (!inputWrapper) return;

      const icon = document.createElement('span');
      icon.classList.add('iconSelect');
      icon.innerHTML = iconHtml;
      inputWrapper.append(icon);
    });
  };

  const handleDisabled = () => {
    const elements: HTMLElement[] = Array.from(
      document.querySelectorAll('[is="a-input"]')
    );

    const checkDisabled = (
      element: HTMLElement,
      input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    ) => {
      element.classList.toggle('isDisabled', input.disabled);
    };

    elements.forEach((element) => {
      const input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement =
        Array.from(element.querySelectorAll('input, textarea, select')).map(
          (x) => {
            if (x.nodeName === 'SELECT') return <HTMLSelectElement>x;
            if (x.nodeName === 'TEXTAREA') return <HTMLTextAreaElement>x;
            return <HTMLInputElement>x;
          }
        )[0];

      checkDisabled(element, input);

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'disabled') {
            checkDisabled(element, input);
          }
        });
      });

      observer.observe(input, { attributes: true });
    });
  };

  handleValidity();
  handleSelect();
  handleDisabled();
};
