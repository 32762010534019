import { FvCustomEventNames } from '../../ts/events';
import { BasisCookieBannerConsentIsGiven } from '../basisCookieBanner';

/**
 * Friendation component
 * @param component Instance of this _Friendation_ component
 */
const BasisCookieNotice = (component: HTMLElement) => {
  /** Name of a cookie that blocks certain elements */
  const cookie = component.dataset.cookie;

  const getBlockedElements = (): HTMLElement[] =>
    Array.from(
      document.querySelectorAll(`[data-blocked-by-cookie="${cookie}"]`)
    );
  const getBlockingElements = (): HTMLElement[] =>
    Array.from(
      document.querySelectorAll(
        `[is="basis-cookie-notice"][data-cookie="${cookie}"]`
      )
    );

  /** ID of any element in the DOM that should be blocked by a {@link cookie} */
  const idToBlock = component.dataset.blockId;

  /** Element that is blocked by a {@link cookie} and can be used to set keyboard focus */
  const blockedElement: HTMLElement = document.getElementById(idToBlock);
  if (blockedElement) blockedElement.dataset.blockedByCookie = cookie;

  const check = () => {
    if (BasisCookieBannerConsentIsGiven(cookie)) {
      getBlockingElements().forEach((element) => {
        element.inert = true;
      });

      getBlockedElements().forEach((x) => {
        x.inert = false;
      });

      if (blockedElement) {
        setTimeout(() => {
          blockedElement.focus();
        }, 100);
      }
    } else {
      getBlockingElements().forEach((x) => {
        x.inert = false;
      });

      if (blockedElement) blockedElement.inert = true;
    }
  };

  document.addEventListener(FvCustomEventNames.CookiesChanged, check);
  check();
};

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-cookie-notice"]';

/** Function that handles a single instance of this _Friendation_ component */
const classFunction: (component: HTMLElement) => void = BasisCookieNotice;

/**
 * Friendation component
 */
export const aBasisCookieNotice = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((x) => {
    classFunction.call(this, x);
  });
};
