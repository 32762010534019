export const aBasisSocialMedia = () => {
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll('[is="basis-social-media-icon"')
  );

  const fetchIcon = async (absolutePath: string) => {
    const body = await fetch(absolutePath).then((r) => r.text());
    return body;
  };

  components.forEach(async (component) => {
    const fileName = component.dataset.icon;
    if (!fileName) {
      component.remove();
      console.error('Missing [data-icon] on', component);
      return;
    }

    const fullPath = `/assets/social/${fileName}`;

    const svg = await fetchIcon(fullPath);
    component.innerHTML = svg;
  });
};
