/**
 * Do some replacements in the text, based on GET parameters in the URL
 */
export const basisTextReplacements = () => {
  /** Params in URL */
  const params = new URL(window.location.href).searchParams;

  /** Elements in DOM that should have their text modified */
  const targetElements: HTMLElement[] = Array.from(
    document.querySelectorAll('h1, h2, h3')
  );

  targetElements.forEach((element) => {
    /** True if {@link element} contains any pattern like %word% */
    const anyMatches = element.innerHTML.match(/%.*?%/);
    if (!anyMatches) return;

    if (params && Array.from(params.entries()).length) {
      for (const [key, value] of params) {
        /** Replace each occurence of {@link searchFor} with {@link value} in {@link element} */
        const searchFor = `%${key}%`;
        const existingText = element.innerHTML.trim();

        if (existingText.includes(searchFor)) {
          const newText = existingText.replace(searchFor, value);

          /** Update `innerHTML` */
          element.innerHTML = newText;
        }
      }
    }

    /** Occurences of %word% in the `innerHTML` of the {@link element} that have not value. */
    const leftOvers = element.innerHTML.matchAll(/%.*?%/g);

    /** Delete the leftovers */
    Array.from(leftOvers).forEach((leftOver) => {
      const searchFor = leftOver[0];
      element.innerHTML = element.innerHTML.replace(searchFor, '');
    });
  });
};
