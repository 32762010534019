export const aGrid = () => {
  const environment = document.documentElement.dataset.env;

  /** Do not show the grid visualizer on production environment */
  if (environment === 'production') return;

  const visualizer = document.createElement('div');
  visualizer.classList.add('gridVisualizer');
  visualizer.hidden = true;

  for (let i = 0; i < 14; i++) {
    const item = document.createElement('div');
    visualizer.append(item);
  }

  document.querySelector('main')?.append(visualizer);

  document.addEventListener('keydown', (e) => {
    if (e.code === 'KeyG') {
      document.querySelector('.gridVisualizer')?.toggleAttribute('hidden');
    }
  });
};
