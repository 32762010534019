export const aBasisFooter = () => {
  const component: HTMLElement = document.querySelector('[is="basis-footer"');
  if (!component) return;

  const handleCleverReach = () => {
    const handleSubmitButton = () => {
      const submitButton: HTMLButtonElement =
        component.querySelector('[type="submit"]');
      if (!submitButton) return;
      submitButton.classList.add('button');
      submitButton.classList.add('button--arrow');
      submitButton.classList.add('button--arrowSmall');
    };

    handleSubmitButton();
  };

  handleCleverReach();
};
