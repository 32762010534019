import { disableBodyScroll } from 'body-scroll-lock';
import { getCookie, setCookie, trapFocus } from '../../ts/helper';
import { FvCustomEventNames } from '../../ts/events';

interface CookieConfig {
  /** Cookie name */
  name: string;

  /** Lifetime in days */
  lifetime: number;

  /** Consent is given? Date of expiration or `undefined` */
  consent: string | false;

  /** Essential */
  essential: boolean;
}

const BasisCookieBannerCookieNames = {
  firstInteraction: 'cookiesFirstInteraction',
  config: 'cookieConfig'
};

/**
 * @returns The current cookie configuration
 */
const BasisCookieBannerConfigurationGet = (): CookieConfig[] => {
  const config = getCookie(BasisCookieBannerCookieNames.config);
  if (!config) return [];
  return JSON.parse(config);
};

/**
 * Overwrite the existing cookie configuration
 * @param config The new configuration that should be saved
 */
const BasisCookieBannerConfigurationSet = (config: CookieConfig[]) => {
  setCookie(BasisCookieBannerCookieNames.config, JSON.stringify(config), 365);

  /** Dispatch an event */
  document.dispatchEvent(new Event(FvCustomEventNames.CookiesChanged));
};

/** User action: accept all cookies */
const BasisCookieBannerConsentAcceptAll = (e: MouseEvent) => {
  BasisCookieBannerOpenSettings();

  const inputs: HTMLInputElement[] = Array.from(
    document.querySelectorAll('[is="basis-cookie-banner"] .group-list input')
  );

  let counter = inputs.filter((x) => !x.disabled).length;

  inputs.forEach((input) => {
    if (input.disabled) return;

    setTimeout(() => {
      input.checked = true;

      setTimeout(() => {
        input.dispatchEvent(new Event('change'));
        counter -= 1;

        if (counter <= 0) {
          BasisCookieBannerClose(e);
        }
      }, 100);
    }, 100);
  });
};

/** Close the cookie banner */
const BasisCookieBannerClose = (e?: MouseEvent) => {
  const trigger: HTMLButtonElement = <HTMLButtonElement>e?.currentTarget;

  if (trigger) {
    trigger.setAttribute('aria-busy', 'true');
  }

  /** Prevent the cookie banner from opening automatically for the next x days. */
  setCookie(BasisCookieBannerCookieNames.firstInteraction, 'true', 365);

  /** Reload page to ensure cookies are set for PHP and Twig */
  window.location.reload();
};

const onKeydown = (e: KeyboardEvent) => {
  if (e.code === 'Escape') {
    const component: HTMLElement = document.querySelector(selector);
    const saveButton: HTMLButtonElement = component.querySelector(
      '.settings-controls button[data-save-settings]'
    );
    setTimeout(() => {
      saveButton.focus();
    }, 300);
  }
};

/** Open the cookie banner */
const BasisCookieBannerOpen = () => {
  const component: HTMLElement = document.querySelector(selector);
  component.inert = false;
  component.tabIndex = -1;

  /** User action: accept all cookies */
  const acceptAllButton: HTMLButtonElement =
    component.querySelector('[data-accept-all]');
  acceptAllButton.addEventListener('click', (e) => {
    acceptAllButton.setAttribute('aria-busy', 'true');
    BasisCookieBannerConsentAcceptAll(e);
  });

  /** User action: open cookie settings */
  const settingsButton: HTMLButtonElement =
    component.querySelector('[data-settings]');
  settingsButton.addEventListener('click', () => {
    BasisCookieBannerOpenSettings(settingsButton);
  });

  document.addEventListener('keydown', onKeydown);

  setTimeout(() => {
    component.focus();
    trapFocus(component);
    disableBodyScroll(component);
  }, 100);
};

/** Check if a cookie already exists in a set of cookies */
const BasisCookieBannerCookieExistsInConfig = (
  config: CookieConfig[],
  cookie: CookieConfig
) => config.find((x) => x.name === cookie.name);

/**
 * Set the consent for a cookie. You can revoke or set the consent.
 * @param cookie Target cookie
 * @param consent Consent
 */
const BasisCookieBannerConsentSet = (
  cookie: CookieConfig,
  consent: CookieConfig['consent']
) => {
  let config = BasisCookieBannerConfigurationGet();

  if (!BasisCookieBannerCookieExistsInConfig(config, cookie)) {
    config.push({ ...cookie, consent });
  } else {
    config.map((x) => {
      if (x.name === cookie.name) x.consent = consent;
    });
  }

  /** Remove cookies with consent set to `false` from the cookie configuaration */
  if (consent === false) config = config.filter((x) => x.consent !== false);

  BasisCookieBannerConfigurationSet(config);
};

/** Set the consent for a cookie according to its lifetime {@link CookieConfig.lifetime} */
const BasisCookieBannerConsentGive = (cookie: CookieConfig) => {
  /** Datetime at which the consent should automatically be revoked */
  const date = new Date();
  date.setTime(date.getTime() + cookie.lifetime * 24 * 60 * 60 * 1000);
  BasisCookieBannerConsentSet(cookie, date.toUTCString());
};

/** Revoke the consent for a cookie */
const BasisCookieBannerConsentRevoke = (cookie: CookieConfig) => {
  BasisCookieBannerConsentSet(cookie, false);
};

/** Check if a cookie is still valid */
const BasisCookieBannerConsentIsValid = (consent: CookieConfig['consent']) => {
  if (!consent) return false;
  const now = new Date();
  const then = new Date(consent);
  return now < then;
};

/**
 * Returns whether the consent for a cookie is given
 */
export const BasisCookieBannerConsentIsGiven = (
  cookieName: string
): CookieConfig['consent'] => {
  const existingCookie = BasisCookieBannerConfigurationGet().find(
    (x) => x.name === cookieName
  );
  if (existingCookie && existingCookie.consent) {
    if (BasisCookieBannerConsentIsValid(existingCookie.consent)) {
      return existingCookie.consent;
    } else {
      BasisCookieBannerConsentRevoke(existingCookie);
    }
  }
  return false;
};

/** Open the settings for cookies */
const BasisCookieBannerOpenSettings = (settingsButton?: HTMLButtonElement) => {
  if (settingsButton) settingsButton.disabled = true;

  const component: HTMLElement = document.querySelector(selector);

  const settingsWrapper: HTMLElement = component.querySelector('.settings');
  settingsWrapper.inert = false;
  settingsWrapper.tabIndex = -1;

  const saveButton: HTMLButtonElement = settingsWrapper.querySelector(
    '.settings-controls button[data-save-settings]'
  );
  saveButton.addEventListener('click', BasisCookieBannerClose);

  const listenForChangesInSettings = () => {
    /** Cookie groups */
    const groups: HTMLElement[] = Array.from(
      settingsWrapper.querySelectorAll('.group')
    );

    groups.forEach((group) => {
      /** Checkbox that toggles all cookies in a cookie group */
      const acceptAllToggle: HTMLInputElement = group.querySelector('input');

      const cookies: HTMLElement[] = Array.from(
        group.querySelectorAll('.cookie')
      );

      const config = BasisCookieBannerConfigurationGet();

      cookies.forEach((cookie) => {
        /** All checkboxes for cookies */
        const cookieInput: HTMLInputElement = cookie.querySelector('input');

        const cookieConfig: CookieConfig = {
          name: cookieInput.name,
          lifetime: parseInt(cookie.dataset.lifetime, 10),
          consent: BasisCookieBannerConsentIsGiven(cookieInput.name),
          essential: cookie.dataset.essential === 'true'
        };

        const onCookieChange = () => {
          if (cookieInput.checked) {
            BasisCookieBannerConsentGive(cookieConfig);
          } else {
            BasisCookieBannerConsentRevoke(cookieConfig);
          }
        };

        const existingCookie = config.find((x) => x.name === cookieConfig.name);
        cookieInput.checked =
          existingCookie && existingCookie.consent !== false;
        cookieInput.addEventListener('change', onCookieChange);
      });

      /** Accept all cookies in a group */
      const onAcceptAllToggle = () => {
        cookies.forEach((cookie) => {
          const input: HTMLInputElement = cookie.querySelector('input');
          if (!input) return;
          if (input.disabled) return;
          input.checked = acceptAllToggle.checked;
          input.dispatchEvent(new Event('change'));
        });
      };

      acceptAllToggle.addEventListener('change', onAcceptAllToggle);
    });
  };

  setTimeout(() => {
    listenForChangesInSettings();
    settingsWrapper.focus();
  }, 100);
};

/**
 * Friendventure custom cookie banner. Dispatches an `Event` {@link FvCustomEventNames.CookiesChanged} whenever a setting for a cookie changes. The cookie banner can be opened from anywhere when dispatching the event {@link FvCustomEventNames.CookieBannerOpen}. All links within the DOM with certain selector {@link triggerSelector} will open the cookie banner {@link handleTriggers}.
 * @param component Instance of this _Friendation_ component
 * @see {@link FvCustomEventNames}
 */
const BasisCookieBanner = (component: HTMLElement) => {
  /** Elements in the `DOM` with this selector will trigger the cookie banner to open */
  const triggerSelector = '[href="#cookie-settings"]';

  /**
   * Behaviour for when the cookie banner should open
   */
  const handleOpenBehaviour = () => {
    setTimeout(() => {
      if (!getCookie(BasisCookieBannerCookieNames.firstInteraction)) {
        BasisCookieBannerOpen();
      }

      document.addEventListener(FvCustomEventNames.CookieBannerOpen, () => {
        BasisCookieBannerOpen();
      });
      document.addEventListener(
        FvCustomEventNames.CookieBannerOpenSettings,
        () => {
          BasisCookieBannerOpen();
          setTimeout(() => {
            const settingsButton: HTMLButtonElement | null =
              component.querySelector('[data-settings]');
            if (settingsButton) {
              BasisCookieBannerOpenSettings(settingsButton);
            } else {
              BasisCookieBannerOpenSettings();
            }
          }, 300);
        }
      );
    }, 500);
  };

  /**
   * Behaviour for `HTMLElements` that trigger the cookie banner on click
   */
  const handleTriggers = () => {
    /** Elements that should open the cookie banner on click */
    const triggers: HTMLElement[] = Array.from(
      document.querySelectorAll(triggerSelector)
    );

    triggers.forEach((trigger) => {
      /** The name of a cookie. We can try to focus that to make it easier for the user to find the setting should be changed. */
      const triggerTarget = trigger.dataset.cookieTarget;
      trigger.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault();
        document.dispatchEvent(
          new Event(FvCustomEventNames.CookieBannerOpenSettings)
        );

        if (triggerTarget) {
          const input: HTMLInputElement | null = document.querySelector(
            `input[name="${triggerTarget}"]`
          );

          if (input) {
            setTimeout(() => {
              input.focus();
              input.classList.add('interactionHighlight');
              input.addEventListener('change', () => {
                input.classList.remove('interactionHighlight');
              });
            }, 200);
          }
        }
      });
    });
  };

  const handleExpiredCookies = () => {
    const cookies = BasisCookieBannerConfigurationGet();
    cookies.forEach((cookie) => {
      if (!BasisCookieBannerConsentIsValid(cookie.consent))
        BasisCookieBannerConsentRevoke(cookie);
    });
  };

  const handleEssentialCookies = () => {
    const cookies: HTMLElement[] = Array.from(
      component.querySelectorAll('[data-essential]')
    );

    cookies.forEach((cookie) => {
      const cookieInput = cookie.querySelector('input');
      const cookieConfig: CookieConfig = {
        name: cookieInput.name,
        lifetime: parseInt(cookie.dataset.lifetime, 10),
        consent: BasisCookieBannerConsentIsGiven(cookieInput.name),
        essential: cookie.dataset.essential === 'true'
      };

      if (cookieConfig.essential) {
        const input = cookie.querySelector('input');
        input.checked = true;
        input.dispatchEvent(new Event('change'));
        input.disabled = true;
        BasisCookieBannerConsentGive(cookieConfig);
      }
    });
  };

  handleExpiredCookies();
  handleEssentialCookies();
  handleOpenBehaviour();
  handleTriggers();
};

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-cookie-banner"]';

/** Function that handles a single instance of this _Friendation_ component */
const classFunction: (component: HTMLElement) => void = BasisCookieBanner;

/**
 * Friendation component
 */
export const aCookieBanner = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((x) => {
    classFunction.call(this, x);
  });
};
