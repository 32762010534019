import {
  convertCssTimeToMilliseconds,
  getCssPropertyValue
} from '../../ts/helper';
import { throttle } from 'throttle-debounce';

export const aBlockCurriculum = () => {
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll('[is="block-curriculum"]')
  );

  components.forEach((component) => {
    const breakpoint = parseInt(
      getCssPropertyValue('--breakpoint-curriculum'),
      10
    );

    /** Element that shows text on click (mobile only) */
    const targetContainer: HTMLElement = component.querySelector(
      '.accordionActiveItem'
    );

    /** Element that contains text on click (mobile only) */
    const targetContainerInner: HTMLElement = targetContainer.querySelector(
      '.accordionActiveItem-inner'
    );

    const triggers: HTMLButtonElement[] = Array.from(
      component.querySelectorAll('[aria-controls]')
    );

    const allTextElements: HTMLElement[] = Array.from(
      component.querySelectorAll('.accordionItem-text')
    );

    const throttleTick = throttle(100, () => {
      if (window.innerWidth < breakpoint) {
        // Reset triggers
        triggers.forEach((x) => {
          x.setAttribute('aria-expanded', 'false');
        });

        allTextElements.forEach((x) => {
          x.inert = true;
        });
      }
    });

    const handleClickEvents = () => {
      triggers.forEach((trigger) => {
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          if (trigger.getAttribute('aria-expanded') === 'true') return;

          triggers.forEach((x) => {
            x.setAttribute('aria-expanded', x === trigger ? 'true' : 'false');
          });

          /** Element that is inert and contains the text that should be visible on click */
          const targetTextElement: HTMLElement = document.getElementById(
            trigger.getAttribute('aria-controls')
          );

          if (window.innerWidth >= breakpoint) {
            // Desktop
            targetContainer.inert = true;
            setTimeout(() => {
              targetContainerInner.innerHTML = targetTextElement.innerHTML;
              targetContainerInner.tabIndex = -1;

              setTimeout(() => {
                targetContainer.inert = false;
                targetContainerInner.focus();
              }, 300);
            }, convertCssTimeToMilliseconds(getCssPropertyValue('--transition-duration')));
          } else {
            allTextElements.forEach((x) => {
              x.inert = true;
            });

            // Mobile
            targetTextElement.inert = false;
            targetTextElement.tabIndex = -1;
            setTimeout(() => {
              targetTextElement.focus();
            }, 100);
          }
        };

        trigger.addEventListener('click', onClick);
      });
    };

    const handleOnLoad = () => {
      if (window.innerWidth >= breakpoint) {
        targetContainerInner.innerHTML = allTextElements[0].innerHTML;
        targetContainer.inert = false;
        triggers[0].setAttribute('aria-expanded', 'true');
      }
    };

    handleClickEvents();
    window.addEventListener('resize', throttleTick);
    handleOnLoad();
  });
};
