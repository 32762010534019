/**
 * Collection of custom `Event` names. It does not really matter what the values are – but they have to be unique!
 * @example
 *   const FvCustomEventNames = {
 *     MyCustomEventName: "foo"
 *   }
 *
 *   // Create Event
 *   const evt = new Event(FvCustomEventNames.MyCustomEventName)
 *
 *   // Listen for Event being fired on `document`
 *   document.addEventListener(FvCustomEventNames.MyCustomEventName, () => {
 *     // do something
 *   })
 *
 *   // Fire Event on `document`
 *   document.dispatchEvent(evt)
 */
export const FvCustomEventNames = {
  /** The component BasisHeader becomes non-visible. The out-animation starts. */
  BasisHeaderOut: 'basisHeaderOut',

  /** The component BasisHeader becomes visible. The in-animation starts. */
  BasisHeaderIn: 'basisHeaderIn',

  /** A setting for a cookie has changed */
  CookiesChanged: 'cookiesChanged',

  /** Opens the cookie banner */
  CookieBannerOpen: 'cookieBannerOpen',

  /** Opens the cookie banner settings */
  CookieBannerOpenSettings: 'cookieBannerOpenSettings'
};
