import { Swiper } from 'swiper/bundle';
import { getCookie } from '../../ts/helper';
/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-professoren-aktuelle"]';
/**
 * Friendation component
 */
export const basisProfessorenAktuelle = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );
  components.forEach((component) => {
    const isMobile = () => window.matchMedia('(max-width: 700px)').matches;
    /**
     * Check if a user has already visited the current page.
     */
    const userAlreadyVisited = () => getCookie('fv-visited') === 'true';
    /**
     * Slider behaviour
     */
    const handleSlider = () => {
      const el = component.querySelector('[data-swiper]');
      const pagination = component.querySelector('[data-swiper-pagination]');
      if (!el || !pagination) return;
      /** @type {import('swiper').SwiperOptions} */
      const config = {
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true
        },
        freeMode: true,
        mousewheel: {
          forceToAxis: true
        },
        watchSlidesProgress: true,
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 20,
        init: false,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 24
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 32
          }
        }
      };
      const swiper = new Swiper(el, {
        ...config,
        on: {
          lock: (swiper) => {
            swiper.el.classList.add('swiper-locked');
            swiper.updateSlides();
          },
          unlock: (swiper) => {
            swiper.el.classList.remove('swiper-locked');
            swiper.updateSlides();
          }
        }
      });
      swiper.init();
    };
    if (!userAlreadyVisited() && isMobile()) {
      setTimeout(() => {
        handleSlider();
      }, 3000);
    } else {
      handleSlider();
    }
  });
};
