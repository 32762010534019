import './index.scss';
import 'wicg-inert';
import 'swiper/css/bundle';
import { init } from './ts/init';

/** Register Serviceworker for Progressive Web App (only works with HTTPS) */
window.addEventListener('load', () => {
  if ('serviceWorker' in navigator)
    navigator.serviceWorker.register('/service-worker.js');
});

/** When the DOM is loaded, execute JavaScript */
document.addEventListener('DOMContentLoaded', () => {
  init();
});
