import {
  getCssPropertyValue,
  setCSSRootValue,
  setCssPropertyValue
} from '../../ts/helper';
import { FvCustomEventNames } from '../../ts/events';
import { throttle } from 'throttle-debounce';

/**
 * Unlock the header. While unlocked, the header will be visible or hidden, based on various factors ({@link BasisHeader}).
 */
export const BasisHeaderUnlock = () => {
  /** The header */
  const header: HTMLElement = document.querySelector(selector);
  header.removeAttribute('data-locked');
};

/**
 * Lock the header. While locked, the header will always be visible
 */
export const BasisHeaderLock = () => {
  /** The header */
  const header: HTMLElement = document.querySelector(selector);
  header.dataset.locked = 'true';
};

/**
 * Friendation component
 * @param component Instance of this _Friendation_ component
 */
const BasisHeader = (component: HTMLElement) => {
  interface VisibilityCallbackData {
    /** Current scroll direction */
    direction: 'visible' | 'hidden';
  }

  /**
   * Behaviour of the component when the user scrolls up or down ({@link currentState})
   * @param minDistance From the top of the page, you have to scroll down a minimum distance before the visibility of {@link component} can change at all.
   * @param upThreshold Scroll up more than this distance in one go: show {@link component}.
   * @param downThreshold Scroll down more than this distance in one go: hide {@link component}.
   * @param callback Callback function is triggered when {@link currentState} changes
   * @requires {@link component}
   * @example
   *   handleVisibility(200, 300, 130, (data) => {
   *     console.log(data.direction) // 'visible' | 'hidden'
   *   })
   */
  const handleVisibility = (
    minDistance: number,
    upThreshold: number,
    downThreshold: number,
    callback?: (data: VisibilityCallbackData) => void
  ) => {
    /** Current state of header */
    let currentState: VisibilityCallbackData['direction'] = 'visible';

    /** Last state of header */
    let lastState: VisibilityCallbackData['direction'] = 'visible';

    /** Represents the window.scrollY position, when {@link currentState} was changed last */
    let lastPos = 0;

    /** Timeout for {@link lastPos} */
    let timeout: ReturnType<typeof setTimeout>;

    /** Time in milliseconds until {@link onScroll} is called again */
    const tick = 100;

    /**
     * The header will hide/show as soon as the user has scrolled a minimum distance of pixels ({@link downThreshold})
     */
    const onScroll = () => {
      clearTimeout(timeout);

      /** Set admin-bar-height */
      const adminBarEl: HTMLElement = document.querySelector('admin-bar');
      if (adminBarEl)
        setCssPropertyValue(
          '--admin-bar-height',
          getCssPropertyValue('--admin-bar-height', adminBarEl)
        );

      /** The scroll position */
      const y = window.pageYOffset || document.documentElement.scrollTop;

      /** Do not detect changes while locked */
      if (component.hasAttribute('data-locked')) {
        currentState = 'visible';
      } else {
        /** The header is always visible, until the user scrolled a {@link minDistance} */
        if (y < minDistance) {
          currentState = 'visible';
          component.dataset.scrollTop = 'true';
        } else {
          component.dataset.scrollTop = 'false';

          /** Scrolling down: You have to scroll down a {@link downThreshold} to change the {@link currentState} to `'hidden'` */
          if (y > lastPos + downThreshold) {
            lastPos = y;
            currentState = 'hidden';
          }

          /** Scrolling up: You have to scroll up a {@link upThreshold} to change the {@link currentState} to `'visible'` */
          if (y < lastPos - upThreshold) {
            lastPos = y;
            currentState = 'visible';
          }
        }
      }

      /** Make changes based on the {@link currentState} */
      component.dataset.currentState = currentState;

      /** Sub-Pixel height rounded down to avoid empty spaces between elements */
      const headerHeight =
        Math.round(parseFloat(window.getComputedStyle(component).height)) - 1;

      setCSSRootValue('--header-height', `${headerHeight}px`);

      setCSSRootValue(
        '--sticky-top',
        currentState === 'hidden' ? '0px' : 'calc(0px + var(--header-height))'
      );

      if (currentState !== lastState) {
        /**
         * Dispatch custom `Event`s. Check the documentation of {@link FvCustomEventNames}
         */
        document.dispatchEvent(
          currentState === 'hidden'
            ? new Event(FvCustomEventNames.BasisHeaderOut)
            : new Event(FvCustomEventNames.BasisHeaderIn)
        );

        /** Callback */
        if (callback) callback.call(this, { direction: currentState });
      }

      lastState = currentState;

      /** Timeout value for {@link timeout}. Time in milliseconds. Keep this above the value of {@link tick} so we don't run into trouble */
      const resetTimeoutAfter: number = tick * 2;

      /** Save the last known position in {@link lastPos} */
      timeout = setTimeout(() => {
        lastPos = y;
      }, resetTimeoutAfter);
    };

    const throttleTick = throttle(100, () => {
      onScroll();
    });

    window.addEventListener('scroll', throttleTick);
    window.addEventListener('resize', throttleTick);
    onScroll();
  };

  handleVisibility(200, 300, 130);
};

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-header"]';

/** Function that handles a single instance of this _Friendation_ component */
const classFunction: (component: HTMLElement) => void = BasisHeader;

/**
 * Friendation component
 */
export const aHeader = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((x) => {
    classFunction.call(this, x);
  });
};
