import { Menu } from '@wanjapflueger/a11y-menu';

/**
 * Friendation component
 * @param component Instance of this _Friendation_ component
 */
const BasisNavigationSingle = (component: HTMLElement) => {
  /** Navigation element */
  const nav = component.querySelector('nav');
  if (!nav) return;

  const myMenu = new Menu();
  myMenu.Create({
    nav
  });
};

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-navigation-single"]';

/** Function that handles a single instance of this _Friendation_ component */
const classFunction: (component: HTMLElement) => void = BasisNavigationSingle;

/**
 * Friendation component
 */
export const aBasisnavigationSingle = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((x) => {
    classFunction.call(this, x);
  });
};
