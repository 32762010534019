import { aBasisCookieNotice } from '../components/basisCookieNotice';
import { aBasisFooter } from '../components/basisFooter';
import { aBasisIcon } from '../components/basisIcon';
import { aBasisnavigationMega } from '../components/basisNavigationMega';
import { aBasisNavigationMobile } from '../components/basisNavigationMobile';
import { aBasisnavigationSingle } from '../components/basisNavigationSingle';
import { aBasisSocialMedia } from '../components/basisSocialMediaIcon';
import { aBlockCurriculum } from '../components/blockCurriculum';
import { aBlockEmbed } from '../components/blockEmbed';
import { aBlockEmbedRaw } from '../components/blockEmbedRaw';
import { aButton } from '../components/button';
import { aCookieBanner } from '../components/basisCookieBanner';
import { aDecorative } from '../components/decorative';
import { aGrid } from '../components/grid';
import { aHeader } from '../components/basisHeader';
import { aInput } from '../components/input';
import { aSearch } from '../components/basisSuche';
import { aSearchResults } from '../components/basisSuchergebnisse';
import { basisBeitraegeAktuelle } from '../components/basisBeitraegeAktuelle';
import { basisBeitraegeArchiv } from '../components/basisBeitraegeArchiv';
import { basisEventsAktuelle } from '../components/basisEventsAktuelle';
import { basisEventsArchiv } from '../components/basisEventsArchiv';
import { basisProfessorenAktuelle } from '../components/basisProfessorenAktuelle';
import { basisProfessorenArchiv } from '../components/basisProfessorenArchiv';
import { basisProfessorenTeaser } from '../components/basisProfessorenTeaser';
import { basisTextReplacements } from '../components/basisTextReplacements';
import { blockAkkordeon } from '../components/blockAkkordeon';
import { blockBildVideoText } from '../components/blockBildVideoText';
import { blockCallToAction } from '../components/blockCallToAction';
import { blockMedia } from '../components/blockMedia';
import { heroBildText } from '../components/heroBildText';
import { heroBildTextKlein } from '../components/heroBildTextKlein';
import { initDelayedAnimations } from './animations';
import { initViewportRelatedAnimations } from './animations';
import { lazyLoading } from './lazyLoading';
import { sliderSymboleTexte } from '../components/sliderSymboleTexte';
import { sliderTestimonials } from '../components/sliderTestimonials';
import { basisForm } from '../components/basisForm';
import { basisFreeform } from '../components/basisFreeform';

export const init = () => {
  aBasisCookieNotice();
  aBasisFooter();
  aBasisIcon();
  aBasisnavigationMega();
  aBasisNavigationMobile();
  aBasisnavigationSingle();
  aBasisSocialMedia();
  aBlockCurriculum();
  aBlockEmbed();
  aBlockEmbedRaw();
  aButton();
  aCookieBanner();
  aDecorative();
  aGrid();
  aHeader();
  aInput();
  aSearch();
  aSearchResults();
  basisBeitraegeAktuelle();
  basisBeitraegeArchiv();
  basisEventsAktuelle();
  basisEventsArchiv();
  basisForm();
  basisFreeform();
  basisProfessorenAktuelle();
  basisProfessorenArchiv();
  basisProfessorenArchiv();
  basisProfessorenTeaser();
  basisProfessorenTeaser();
  blockAkkordeon();
  blockBildVideoText();
  blockCallToAction();
  blockMedia();
  heroBildText();
  heroBildTextKlein();
  initDelayedAnimations();
  initViewportRelatedAnimations();
  sliderSymboleTexte();
  sliderTestimonials();

  /** Execute last */
  lazyLoading();
  basisTextReplacements();
};
