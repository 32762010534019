import Rellax from 'rellax';
import { getRandomNumberInRange } from '../../ts/helper';

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="a-decorative"]';

/**
 * Friendation component
 */
export const aDecorative = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((component) => {
    const handleParallax = () => {
      const item: HTMLDivElement = component.querySelector('.element');

      if (!item) return;

      new Rellax(item, {
        speed: getRandomNumberInRange(-1.2, 1.2),
        center: true,
        percentage: 0.5
      });
    };

    handleParallax();
  });
};
