import { Swiper } from 'swiper/bundle';
/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-beitraege-aktuelle"]';
/**
 * Friendation component
 */
export const basisBeitraegeAktuelle = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );
  components.forEach((component) => {
    const isTablet = () => window.matchMedia('(max-width: 1023px)').matches;
    /**
     * Slider behaviour
     */
    const handleSlider = () => {
      const el = component.querySelector('[data-swiper]');
      const pagination = component.querySelector('[data-swiper-pagination]');
      if (!el || !pagination) return;
      /** @type {import('swiper').SwiperOptions} */
      const config = {
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true
        },
        freeMode: true,
        mousewheel: {
          forceToAxis: true
        },
        watchSlidesProgress: true,
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 20,
        init: false,
        autoHeight: true,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 24
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 32
          }
        }
      };

      let swiper = null;
      let isSwiperInitialized = false;

      const initializeSwiper = () => {
        swiper = new Swiper(el, {
          ...config,
          on: {
            lock: (swiper) => {
              swiper.el.classList.add('swiper-locked');
              swiper.updateSlides();
            },
            unlock: (swiper) => {
              swiper.el.classList.remove('swiper-locked');
              swiper.updateSlides();
            }
          }
        });

        swiper.init();
        isSwiperInitialized = true;
      };

      const destroySwiper = () => {
        if (swiper) {
          swiper.destroy(true, true);
          swiper = null;
        } else {
          isSwiperInitialized = false;
        }
      };

      const checkAndInitSwiper = () => {
        if (isTablet() && !isSwiperInitialized) {
          initializeSwiper();
        } else if (!isTablet() && isSwiperInitialized) {
          destroySwiper();
        }
      };

      checkAndInitSwiper();

      window.addEventListener('resize', checkAndInitSwiper);
    };

    handleSlider();
  });
};
