import { VimeoIframeFromUrl, YouTubeIframeFromUrl } from '../../ts/helper';

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="block-media"]';

/**
 * Friendation component
 */
export const blockMedia = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((component) => {
    /**
     * Behaviour for YouTube and Vimeo
     */
    const handleVideo = () => {
      /**
       * Identify a supported video platform from a url
       * @param {string} src Video URL
       * @returns {'youtube'|'vimeo'|undefined} Platform name
       */
      const findVideoPlatform = (src) => {
        if (src.includes('youtube') || src.includes('youtu.be')) {
          return 'youtube';
        } else if (src.includes('vimeo')) {
          return 'vimeo';
        } else {
          return undefined;
        }
      };

      /** Here goes the iframe */
      const playerWrapper: HTMLElement =
        component.querySelector('.video-player');

      /** Overlay with play button */
      const overlay: HTMLElement = component.querySelector('.video-overlay');

      /** Loading overlay with icon that tells the user „something is happening” */
      const loader: HTMLElement = component.querySelector('.video-loader');

      /** Poster */
      const poster: HTMLElement = component.querySelector('.video-poster');

      /** Play button */
      const playButton: HTMLButtonElement =
        component.querySelector('.video-overlay');

      if (playerWrapper && overlay && playButton && loader && poster) {
        playButton.addEventListener('click', () => {
          /**  Video URL */
          const url: string = playerWrapper.dataset.url;

          if (url) {
            loader.classList.add('video-loader--isLoading');
            overlay.remove();

            /** Video platform */
            const platform = findVideoPlatform(url);

            /** Player Iframe */
            let iframe: HTMLIFrameElement;

            if (platform === 'youtube' || url.includes('youtu.be')) {
              iframe = YouTubeIframeFromUrl(url);
            } else if (platform === 'vimeo') {
              iframe = VimeoIframeFromUrl(url);
            }

            if (iframe) {
              playerWrapper.append(iframe);

              setTimeout(() => {
                loader.remove();
                poster.remove();
                playerWrapper.classList.add('video-player--isLoaded');
                iframe.tabIndex = 0;
                iframe.focus();
              }, 1000); // timeout is only here so the iframe has a little time to load
            }
          }
        });
      }
    };

    handleVideo();
  });
};
