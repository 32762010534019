import { a11yAccordion } from './accordion/accordion';
import { getCookie } from '../../ts/helper';

// console.log(panelId);

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="block-akkordeon"]';

/**
 * Friendation component
 */
export const blockAkkordeon = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((component) => {
    /**
     * Detect mobile breakpoints
     */
    const isMobile = () => window.matchMedia('(max-width: 700px)').matches;

    /**
     * Check if a user has already visited the current page.
     */
    const userAlreadyVisited = () => getCookie('fv-visited') === 'true';

    /**
     * Accordion behaviour
     */
    const handleAccordion = () => {
      a11yAccordion({
        el: component.querySelector('.accordion'),
        triggers: component.querySelectorAll('.panel .panel-trigger'),
        // onTrigger,c
        multiple: true,
        toggle: true
      });

      const triggers: HTMLElement[] = Array.from(
        component.querySelectorAll('.panel .panel-trigger')
      );
      triggers.forEach((trigger) => {
        trigger.setAttribute('aria-expanded', 'false');

        const onTrigger = () => {
          trigger.parentElement?.classList.toggle(
            'backgroundTwo',
            trigger.getAttribute('aria-expanded') === 'false'
          );
          trigger.parentElement?.classList.toggle(
            'backgroundOne',
            trigger.getAttribute('aria-expanded') === 'true'
          );
        };

        trigger.addEventListener('click', onTrigger);
      });

      const content: HTMLElement[] = Array.from(
        component.querySelectorAll('.panel .panel-content')
      );
      content.forEach((item) => {
        item.inert = true;
      });

      // const firstChild = component.querySelector('.accordion').firstChild;
      // firstChild
      //   .querySelector('.panel .panel-trigger')
      //   .setAttribute('aria-expanded', 'true');
      // firstChild.querySelector('.panel .panel-content').inert = false;
    };

    if (!userAlreadyVisited() && isMobile()) {
      setTimeout(() => {
        handleAccordion();
      }, 3000);
    } else {
      handleAccordion();
    }
  });
};
