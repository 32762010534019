/**
 * Set custom property value
 * @param key Key
 * @param value Value
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties}
 * @example
 *   setCssPropertyValue('--foo', 'bar');
 *
 *   :root {
 *     --foo: 'bar'
 *   }
 */
export const setCssPropertyValue = (key: string, value: string) =>
  document.body.style.setProperty(key, value);

/**
 * Get custom property value
 * @param key Property name like `'--my-variable'`
 * @param element Any target element
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties}
 * @example
 *   ```css
 *   :root {
 *     --foo: 'bar'
 *   }
 *   ```
 *
 *   ```js
 *   getCssPropertyValue('--foo'); // 'bar'
 *   ```
 *
 *   ```css
 *   .foo {
 *     --foo: 'bar'
 *   }
 *   ```
 *
 *   ```js
 *   getCssPropertyValue('--foo', document.querySelector('.foo')); // 'bar'
 *   ```
 */
export const getCssPropertyValue = (
  key: string,
  element: HTMLElement = document.body
) => window.getComputedStyle(element).getPropertyValue(key).trim();

/**
 * Returns a random number between min (inclusive) and max (inclusive)
 * @param min Minimum value
 * @param max Maximum value
 * @returns Value between {@link min} and {@link max}
 * @example
 *   getRandomNumberInRange(1, 3); // 1.28
 *   getRandomNumberInRange(1, 3); // 2.93
 */
export const getRandomNumberInRange = (min: number, max: number): number => {
  // Generate a random decimal between 0 and 1
  const randomDecimal = Math.random();

  // Scale the random decimal to the desired range
  const scaledRandom = randomDecimal * (max - min) + min;

  // Round the scaled random value to 2 decimal places
  const roundedRandom = Math.round(scaledRandom * 100) / 100;

  // Return the final result
  return roundedRandom;
};

/**
 * Find YouTube Video ID
 * @param {string} src Any YouTube url
 * @returns {string | undefined} YouTube Video ID
 */
export const YouTubeIdFromUrl = (src) => {
  if (!src) return;

  let parts = src.split('v=');
  if (!parts || parts.length < 2) {
    parts = src.split('.be/');
  }
  if (!parts || parts.length < 2) return;

  let id = parts[1];
  if (!id) return;

  const ampersandPosition = id.indexOf('&');
  if (ampersandPosition !== -1) {
    id = id.substring(0, ampersandPosition);
  }

  return id;
};

/**
 * Create YouTube iframe from a url
 * @param {string} url YouTube url
 * @returns {HTMLIFrameElement} YouTube iframe
 */
export const YouTubeIframeFromUrl = (url) => {
  const id = YouTubeIdFromUrl(url);

  if (id) {
    const el = document.createElement('iframe');
    el.src = `https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=1`;
    // el.src = el.src + '&mute=1'
    el.allow =
      'accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture';
    return el;
  }
};

/**
 * Find Vimeo Video ID
 * @param {string} src Any Vimeo url
 * @returns {string | undefined} Vimeo Video ID
 */
export const VimeoIdFromUrl = (src) => {
  if (!src) return;
  return src.match(
    /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/
  )[4];
};

/**
 * Create Vimeo iframe from a url
 * @param {string} url Vimeo url
 * @returns {HTMLIFrameElement} Vimeo iframe
 */
export const VimeoIframeFromUrl = (url) => {
  const id = VimeoIdFromUrl(url);

  if (id) {
    const el = document.createElement('iframe');
    el.src = `https://player.vimeo.com/video/${id}?dnt=1&portrait=0&autoplay=true&title=0&byline=0`;
    // el.src = el.src + '&muted=1'
    el.allow = 'autoplay; fullscreen; picture-in-picture';
    return el;
  }
};

/*
 * Trap focus on element. This can improve the accessibility with Popups/overlays (A11Y.
 * @param el Any HTML Element
 * @see {@link https://gomakethings.com/how-to-get-the-first-and-last-focusable-elements-in-the-dom/}
 * @returns Function that removes all events listeners that were added by this function
 * @example
 *   const removeTrapFocus = trapFocus(document.createElement('div')) // focus is trapped
 *   removeTrapFocus() // focus is no longer trapped
 */
export const trapFocus = (el: HTMLElement) => {
  /** Elements in {@link el} that can be focussed */
  const elements: HTMLElement[] = Array.from(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  );

  /** The first focussable element in {@link el} */
  let first: HTMLElement | null = null;

  /** The last focussable element in {@link el} */
  let last: HTMLElement | null = null;

  if (elements && elements.length) {
    first = elements[0];
    last = elements[elements.length - 1];
  }

  /**
   * On keydown on {@link first}
   * @param e Keyboard event
   */
  const onKeyDownFirst = (e: KeyboardEvent) => {
    if (e.code === 'Tab' && e.shiftKey) {
      e.preventDefault();
      setTimeout(() => {
        last?.focus();
      }, 100);
    }
  };

  /**
   * On keydown on {@link last}
   * @param e Keyboard event
   */
  const onKeyDownLast = (e: KeyboardEvent) => {
    if (e.code === 'Tab') {
      if (!e.shiftKey) {
        e.preventDefault();
        setTimeout(() => {
          first?.focus();
        }, 100);
      }
    }
  };

  if (first || last) {
    first?.addEventListener('keydown', onKeyDownFirst);
    last?.addEventListener('keydown', onKeyDownLast);
  }

  /**
   * Remove all event listeners
   */
  const cleanup = () => {
    first?.removeEventListener('keydown', onKeyDownFirst);
    last?.removeEventListener('keydown', onKeyDownLast);
  };

  return cleanup;
};

/**
 * Set CSS root variable value
 * @param key Variable name
 * @param key Variable balue
 * @example
 *   setCSSRootValue('--foo', 'bar');
 *
 *   :root {
 *     --foo: 'bar'
 *   }
 */
export const setCSSRootValue = (key: string, value: string) => {
  return document.body.style.setProperty(key, value);
};

/**
 * Detect click outside of an element
 * @param el Any element
 * @param callback Callback function
 * @returns {() => void} Function that removes all events listeners that were added by this function
 * @example
 *   const removeOnClickOutside = onClickOutside(document.getElementById('foo'), () => {
 *     console.log('you clicked outside of the element')
 *   }) // now listening
 *   removeOnClickOutside() // not listening anymore
 */
export const onClickOutside = (el: HTMLElement, callback?: () => void) => {
  /**
   * Clicking on the {@link el} or any child will do nothing
   * @param e Click event
   */
  const clickInside = (e: MouseEvent) => e.stopPropagation();

  /**
   * Clicking outside of the {@link el} will call the callback function and remove the event listeners attached
   */
  const clickOutside = () => {
    if (callback) callback.call(this);
    el.removeEventListener('mouseup', clickInside);
  };

  document.addEventListener('mouseup', clickOutside, { once: true });
  el.addEventListener('mouseup', clickInside);

  /**
   * Remove all event listeners
   */
  const cleanup = () => el.removeEventListener('mouseup', clickInside);

  return cleanup;
};

/**
 * `getBoundingClientRect` without transforms. This allows you to use `transform: translateY(10px)` on an element – but get the original `DOMRect` without the transformed position. Does not work with `skew` or `rotate`.
 * @see {@link https://stackoverflow.com/a/57876601}
 * @author Adam Leggett
 * @param el Any element
 */
export const getBoundingClientRectWithoutTransform = (
  el: HTMLElement
): DOMRectReadOnly => {
  const rect = el.getBoundingClientRect();
  const style = window.getComputedStyle(el);
  const tx = style.transform;

  let ta: string[];

  if (tx) {
    let sx: number;
    let sy: number;
    let dx: number;
    let dy: number;

    if (tx.startsWith('matrix3d(')) {
      ta = tx.slice(9, -1).split(/, /);
      sx = +ta[0];
      sy = +ta[5];
      dx = +ta[12];
      dy = +ta[13];
    } else if (tx.startsWith('matrix(')) {
      ta = tx.slice(7, -1).split(/, /);
      sx = +ta[0];
      sy = +ta[3];
      dx = +ta[4];
      dy = +ta[5];
    } else {
      return rect;
    }

    const to = style.transformOrigin;
    const x = rect.x - dx - (1 - sx) * parseFloat(to);
    const y =
      rect.y - dy - (1 - sy) * parseFloat(to.slice(to.indexOf(' ') + 1));
    const w = sx ? rect.width / sx : el.offsetWidth;
    const h = sy ? rect.height / sy : el.offsetHeight;

    const rectWithoutTransform: DOMRectReadOnly = {
      x,
      y,
      width: w,
      height: h,
      top: y,
      right: x + w,
      bottom: y + h,
      left: x,
      toJSON: () => {
        return;
      }
    };
    return rectWithoutTransform;
  } else {
    return rect;
  }
};

/**
 * Wrap all occurrences of a {@link keyword} in an {@link element} with `<mark>` element
 * @param element Any element containing only text
 * @param keyword The search keyword
 */
export function wrapTextWithSpan(element: HTMLElement, keyword: string): void {
  const textArry =
    element.textContent?.trim().replace(/\s/g, ' ').split(' ') || [];

  textArry.forEach((text, index) => {
    if (text.toLowerCase().includes(keyword.toLowerCase())) {
      textArry[index] = `<mark>${text}</mark>`;
    }
  });
  const output = textArry.join(' ');
  element.innerHTML = output;
}

/**
 * Set cookie
 * @param name Name
 * @param value Value
 * @param days Days this cookie will be stored. Default is „Session”
 * @param path Cookie path (default is `'/'`)
 *
 * @example
 * setCookie('myCookie', 'someValue', 7); // Set a cookie named 'myCookie' with the value 'someValue' that will expire in 7 days
 */
export const setCookie = (
  name: string,
  value?: string | number | object,
  days?: number,
  path = '/'
): void => {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `;expires=${date.toUTCString()}`;
  }

  let cookieValue = '';

  if (typeof value === 'object') {
    cookieValue = JSON.stringify(value);
  } else {
    cookieValue = value ? value.toString() : '';
  }

  // set cookie
  document.cookie = `${name}=${cookieValue}${expires};path=${path}`;
};

/**
 * Get cookie value
 * @param name Cookie name
 * @param convertType Whether to try to convert the returned value to its actual type (default is `false`)
 * @returns Cookie value
 * @example
 *   // Suppose the user has a cookie named 'token' with the value 'abcdef123456'
 *   const token = getCookie('token');
 *   console.log(token); // Output: 'abcdef123456'
 *   // Suppose the user has a cookie named 'age' with the value '22'
 *   const age = getCookie('age', true);
 *   console.log(age); // Output: 22
 */
export const getCookie = (name: string, convertType = false): any => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookieValue = parts.pop()?.split(';').shift();
    if (convertType && cookieValue) {
      if (cookieValue === 'true' || cookieValue === 'false') {
        return cookieValue === 'true';
      } else {
        try {
          const parsedValue = JSON.parse(cookieValue);
          if (typeof parsedValue === 'number') {
            return Number(parsedValue);
          } else {
            return parsedValue;
          }
        } catch (error) {
          return cookieValue;
        }
      }
    } else {
      return cookieValue;
    }
  } else {
    return null;
  }
};

/**
 * Generate hash from any string.
 * @param s Any string
 * @returns Hash value like `"1805474036"`
 */
export const generateHashFromString = (s: string) => {
  return s
    .split('')
    .reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0)
    .toString();
};

/**
 * Convert a CSS time unit like `0.25s` to a `number` representing milliseconds.
 * @param duration Any CSS time unit like `0.25s` or `250ms`
 * @returns Time in milliseconds like `250`
 * @example
 *   convertCssTimeToMilliseconds('0.25s'); // `250`
 *   convertCssTimeToMilliseconds('250ms'); // `250`
 */
export const convertCssTimeToMilliseconds = (duration: string): number =>
  parseFloat(duration) * (/\ds$/.test(duration) ? 1000 : 1);
