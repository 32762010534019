/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-beitraege-archiv"]';

/**
 * Friendation component
 */
export const basisBeitraegeArchiv = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((component) => {
    /**
     * Load More behaviour
     */
    const handleLoadMore = () => {
      const posts = component.querySelectorAll('.post');
      const loadMoreBtn = component.querySelector('.posts-loadMore-button');
      const maxPosts = 9;
      let visiblePosts = 0;

      posts.forEach((post) => {
        if (!post.classList.contains('post-hide')) {
          visiblePosts++;
        }
      });

      if (visiblePosts > maxPosts) {
        for (let i = maxPosts; i < visiblePosts; i++) {
          posts[i].classList.add('post-hide');
        }

        loadMoreBtn?.setAttribute('data-hide', 'false');

        loadMoreBtn.addEventListener('click', () => {
          for (let i = maxPosts; i < visiblePosts; i++) {
            posts[i].classList.remove('post-hide');
          }
          loadMoreBtn?.setAttribute('data-hide', 'true');
        });
      } else {
        loadMoreBtn?.setAttribute('data-hide', 'true');
      }
    };

    handleLoadMore();
  });
};
