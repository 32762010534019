if (document.body.id === 'basestyle') {
  const themes = [
    'backgroundOne',
    'backgroundTwo',
    'backgroundThree',
    'backgroundFour'
  ];
  let themeIndex = 0;
  let currentButtonState: 'enabled' | 'disabled' = 'enabled';

  /**
   * Change state of components in the BaseStyle
   * @param e Keyboard event
   */
  const onKeydown = (e: KeyboardEvent) => {
    /** Toggle {@link themes} on keydown */
    if (e.code === 'KeyT') {
      const elements: HTMLElement[] = Array.from(
        document.querySelectorAll('.friendation')
      );

      const currentThemeElement: HTMLSpanElement =
        document.getElementById('currentTheme');

      themeIndex++;
      if (themeIndex > themes.length - 1) themeIndex = 0;

      elements.forEach((element) => {
        element.classList.remove(...themes);
        element.classList.add(themes[themeIndex]);
      });

      currentThemeElement.innerText = themes[themeIndex];
    }

    /** Toggle disable buttons */
    if (e.code === 'KeyD') {
      const elements: (
        | HTMLAnchorElement
        | HTMLButtonElement
        | HTMLInputElement
        | HTMLTextAreaElement
        | HTMLSelectElement
      )[] = Array.from(
        document.querySelectorAll('.button, input, select, textarea')
      );

      const currentButtonStateElement: HTMLSpanElement =
        document.getElementById('currentButtonState');

      currentButtonState =
        currentButtonState === 'enabled' ? 'disabled' : 'enabled';
      currentButtonStateElement.innerText = currentButtonState;

      elements.forEach((button) => {
        if (button.hasAttribute('disabled')) {
          button.removeAttribute('disabled');
        } else {
          button.setAttribute('disabled', '');
        }
      });
    }
  };

  document.addEventListener('keydown', onKeydown);
}
