import { throttle } from 'throttle-debounce';
import { FvCustomEventNames } from '../../ts/events';
import { BasisCookieBannerConsentIsGiven } from '../basisCookieBanner';

export const aBlockEmbed = () => {
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll('[is="block-embed"]')
  );

  components.forEach((component) => {
    const embedWrapper: HTMLElement | null = component.querySelector('.embed');
    if (!embedWrapper || !embedWrapper.dataset.url.length) return;
    const url = new URL(embedWrapper.dataset.url);
    url.searchParams.append('consent', 'ok');

    const object = document.createElement('object');
    object.type = 'text/html';
    object.data = url.href;

    /** Update the height of the embedded contents and their wrappers */
    const update = () => {
      const bodyHeight =
        object.contentDocument?.body?.getBoundingClientRect().height;

      if (bodyHeight) {
        if (bodyHeight > 100) {
          embedWrapper.style.minHeight = `${bodyHeight}px`;
          embedWrapper.style.maxHeight = `${bodyHeight}px`;
          object.style.height = `${bodyHeight}px`;
        }
      } else {
        embedWrapper.style.minHeight = `unset`;
        embedWrapper.style.maxHeight = `unset`;
        object.style.height = `unset`;
      }
    };

    /** Cycles to run */
    let repeatTimes = 3;

    /** Time in milliseconds */
    const repeatAfter = 3000;

    /** Callback for {@link object} */
    const onLoad = () => {
      update();

      /** After the `load` event that we attached to the {@link object}, we fire {@link update} {@link repeatAfter} times with a delay of {@link repeatAfter} */
      setTimeout(() => {
        update();

        repeatTimes -= 1;

        if (repeatTimes > 0) {
          setTimeout(() => {
            update();
          }, repeatAfter);
        }
      }, repeatAfter);
    };

    const throttleTick = throttle(100, () => {
      update();
    });

    object.addEventListener('load', onLoad);
    window.addEventListener('resize', throttleTick);

    /**
     * DSGVO. Embedded content is hidden by default. We need to actively get the users consent.
     */
    const handlePrivacy = () => {
      const check = () => {
        if (BasisCookieBannerConsentIsGiven('externalEmbed')) {
          revealContent();
        } else {
          hideContent();
        }
      };

      document.addEventListener(FvCustomEventNames.CookiesChanged, check);

      const hideContent = () => {
        embedWrapper.innerHTML = '';
        embedWrapper.style.minHeight = `unset`;
        embedWrapper.style.maxHeight = `unset`;
        object.style.height = `unset`;
      };

      /**
       * Reveal the embedded content
       */
      const revealContent = () => {
        embedWrapper.append(object);

        // /** First element in {@link embedWrapper} that we can focus */
        // const firstElement: HTMLElement = object.querySelector('*');
        // if (firstElement) {
        //   firstElement.tabIndex = -1;
        //   setTimeout(() => {
        //     firstElement.focus();
        //   }, 100);
        // }

        // handleIframe();
      };
    };

    /**
     * Try to set a `height` for any iframe within the {@link embedWrapper}
     */
    // const handleIframe = () => {
    //   const iframe: HTMLIFrameElement = embedWrapper.querySelector('iframe');

    //   if (iframe) {
    //     /** Iframe inner height in pixel. Possibly throws an error when the client blocks this information. */
    //     let height: number;

    //     iframe.addEventListener('load', () => {
    //       try {
    //         /** This might be blocked by the client CORS policy */
    //         height =
    //           iframe.contentWindow &&
    //             iframe.contentWindow.document &&
    //             iframe.contentWindow.document.body &&
    //             iframe.contentWindow.document.body.scrollHeight
    //             ? iframe.contentWindow.document.body.scrollHeight
    //             : undefined;
    //       } catch (error) {
    //         height = parseInt(component.dataset.minHeight, 10);
    //         console.warn(
    //           `${error} Using minimumHeight of ${height}px instead.`
    //         );
    //       }

    //       if (typeof height === 'number' && height > 0)
    //         iframe.style.height = height + 'px';

    //       /** Fix iframes that come with `<iframe scrolling="no">` for no reason at all. */
    //       if (iframe.getAttribute('scrolling') === 'no')
    //         iframe.setAttribute('scrolling', 'yes');
    //     });
    //   }
    // };

    handlePrivacy();
  });
};
