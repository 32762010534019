import { onClickOutside, trapFocus } from '../../ts/helper';
import { FvCustomEventNames } from '../../ts/events';
import { animations } from '../../ts/animations';

const settings = {
  /** When opening the {@link theSearchElement}, the focus will jump into the `input[type="search"]`. Set this to `true` if there is some text above the input, that should be read first. The focus will then be on the searchbar instead. */
  focusOnDialog: false
};

/** The search element */
let theSearchElement: HTMLElement;

/** Returns `true` if the {@link theSearchElement} is currently open */
const isOpen = (): boolean => {
  if (!theSearchElement) throw Error("Missing required argument 'searchBar'");
  return !theSearchElement.inert;
};

/** Elements that control {@link theSearchElement} */
const triggers = () => {
  if (!theSearchElement) throw Error("Missing required argument 'searchBar'");
  return Array.from(
    document.querySelectorAll(`[aria-controls="${theSearchElement.id}"]`)
  );
};

/** Any element that possibly triggered {@link BasisSucheOpen} */
let activeElement: HTMLButtonElement | HTMLAnchorElement | undefined;

/**
 * Listen for keyboard input while the {@link theSearchElement} is open.
 * @param e Keydown
 */
const onKeydown = (e: KeyboardEvent) => {
  if (e.code === 'Escape') {
    BasisSucheClose(true);
  }
};

/**
 * Open or close the searchbar based on its current state
 */
export const BasisSucheToggle = () => {
  if (!isOpen()) BasisSucheOpen();
};

/**
 * Open the searchbar. Will trap the focus inside the searchbar and disable body scrolling. The searchbar can be closed with the "Escpape" key.
 * @requires {@link theSearchElement}
 */
export const BasisSucheOpen = () => {
  activeElement =
    document.activeElement.nodeName === 'A'
      ? <HTMLAnchorElement>document.activeElement
      : <HTMLButtonElement>document.activeElement;

  if (!theSearchElement) throw Error("Missing required argument 'searchBar'");

  /** Mark all triggers as 'expanded' */
  triggers().forEach((item) => {
    item.setAttribute('aria-expanded', 'true');
  });

  animations.slideDownFade(theSearchElement, () => {
    trapFocus(theSearchElement);
    document.addEventListener('keydown', onKeydown);
    onClickOutside(theSearchElement, BasisSucheClose);

    if (settings.focusOnDialog) {
      /** Focus on dialog */
      theSearchElement.tabIndex = -1;
      setTimeout(() => {
        theSearchElement.focus();
      }, 1);
    } else {
      /** Search input */
      const input: HTMLInputElement = theSearchElement.querySelector('input');

      /** Jump into the input directly */
      setTimeout(() => {
        input.focus();
      }, 1);
    }
  });
};

/**
 * Close the searchbar
 * @requires {@link theSearchElement}
 * @param focus If `true` will focus on the {@link activeElement} after animation is done
 */
export const BasisSucheClose = (focus?: boolean) => {
  if (!theSearchElement) return;

  /** Mark all triggers as not 'expanded' */
  triggers().forEach((item) => {
    item.setAttribute('aria-expanded', 'false');
  });

  const onDone = () => {
    if (focus && activeElement) {
      setTimeout(() => {
        activeElement.focus();
        activeElement = undefined;
      }, 1);
    }
  };

  animations.slideUpFade(theSearchElement, onDone);
};

/**
 * Friendation component
 * @param component Instance of this _Friendation_ component
 */
const BasisSuche = (component: HTMLElement) => {
  theSearchElement = component;

  /**
   * Each element in DOM with `aria-controls="${this.id}"` will toggle {@link component}
   */
  const handleTriggers = () => {
    triggers().forEach((item) => {
      item.setAttribute('aria-expanded', 'false');
      item.setAttribute('aria-haspopup', 'true');
      item.addEventListener('click', BasisSucheToggle);
    });
  };

  handleTriggers();

  /** Listen for header going non-visible */
  document.addEventListener(FvCustomEventNames.BasisHeaderOut, () => {
    BasisSucheClose();
  });
};

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-suche"]';

/** Function that handles a single instance of this _Friendation_ component */
const classFunction: (component: HTMLElement) => void = BasisSuche;

/**
 * Friendation component
 */
export const aSearch = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((x) => {
    classFunction.call(this, x);
  });
};
