import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { onClickOutside, setCSSRootValue, trapFocus } from '../../ts/helper';

let mobile: HTMLDivElement;
let mobileWrapper: HTMLElement;
let currentTrigger: HTMLButtonElement;
const triggers = (): HTMLButtonElement[] => {
  return Array.from(
    document.querySelectorAll(`[aria-controls="${mobile.id}"]`)
  );
};
let focusTrap: () => void;

const onKeydown = (e: KeyboardEvent) => {
  if (e.code === 'Escape') {
    BasisNavigationMobileClose();
  }
};

export const BasisNavigationMobileOpen = () => {
  mobile.classList.add('hasBeenOpened');
  triggers().forEach((trigger) => {
    trigger.setAttribute('aria-expanded', 'true');
  });

  mobile.inert = false;
  disableBodyScroll(mobile);
  mobile.tabIndex = -1;

  onClickOutside(mobileWrapper, BasisNavigationMobileClose);
  setCSSRootValue(
    '--mobile-header-spacing',
    `calc(${window.innerHeight}px - var(--sticky-top))`
  );

  window.addEventListener('resize', BasisNavigationMobileClose, { once: true });
  setTimeout(() => {
    mobile.focus();
    focusTrap = trapFocus(mobile);
    document.addEventListener('keydown', onKeydown);
  }, 100);
};

export const BasisNavigationMobileClose = () => {
  if (mobile.inert) return;
  triggers().forEach((trigger) => {
    trigger.setAttribute('aria-expanded', 'false');
  });

  mobile.inert = true;
  if (focusTrap && typeof focusTrap === 'function') {
    focusTrap();
  }
  document.removeEventListener('keydown', onKeydown);
  clearAllBodyScrollLocks();
  if (currentTrigger) {
    setTimeout(() => {
      currentTrigger.focus();
    }, 100);
  }
};

/**
 * Friendation component
 * @param component Instance of this _Friendation_ component
 */
const BasisNavigationMobile = (component: HTMLElement) => {
  mobile = component.querySelector('.mobile');
  mobileWrapper = component;

  triggers().forEach((trigger) => {
    trigger.setAttribute('aria-expanded', 'false');

    trigger.addEventListener('click', (e: MouseEvent) => {
      currentTrigger = <HTMLButtonElement>e.currentTarget;

      if (trigger.getAttribute('aria-expanded') === 'true') {
        BasisNavigationMobileClose();
      } else {
        BasisNavigationMobileOpen();
      }
    });
  });
};

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-navigation-mobile"]';

/** Function that handles a single instance of this _Friendation_ component */
const classFunction: (component: HTMLElement) => void = BasisNavigationMobile;

/**
 * Friendation component
 */
export const aBasisNavigationMobile = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((x) => {
    classFunction.call(this, x);
  });
};
