import { wrapTextWithSpan } from '../../ts/helper';

/**
 * Friendation component
 * @param component Instance of this _Friendation_ component
 */
const BasisSuchergebnisse = (component: HTMLElement) => {
  const handleKeywordHighlighting = () => {
    /** The current keyword */
    const keyword = component.dataset.keyword;

    if (keyword && keyword.length) {
      const items: HTMLAnchorElement[] = Array.from(
        component.querySelectorAll('.result')
      );

      items.forEach((item) => {
        /** A text element that may contain {@link keyword} */
        const text: HTMLElement = item.querySelector('.result-text-content');

        if (text) {
          /** Highlight {@link keyword} in {@link text} */
          wrapTextWithSpan(text, keyword);
          // console.log(keyword, text, );
        }
      });
    }
  };

  handleKeywordHighlighting();
};

/** Selector for any instance of this _Friendation_ component */
const selector = '[is="basis-suchergebnisse"]';

/** Function that handles a single instance of this _Friendation_ component */
const classFunction: (component: HTMLElement) => void = BasisSuchergebnisse;

/**
 * Friendation component
 */
export const aSearchResults = () => {
  /** Instances of this Friendation component */
  const components: HTMLElement[] = Array.from(
    document.querySelectorAll(selector)
  );

  components.forEach((x) => {
    classFunction.call(this, x);
  });
};
